'use es6';

import { SHOW_LOADING, RELOAD_WITHOUT_WARNING, I18N_READY, SET_IFRAME_READY, SET_IFRAME_RENDERED, SET_IFRAME_LOADING, SET_IFRAME_HIDDEN, SET_ADD_MODULE_SIDEBAR_LOADED, HIDE_LOADING, IFRAME_CLICKED, SET_VALIDATION_POPOVER_OPEN, OPEN_POPOVER, DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON, CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON, CONTENT_SAVE_REQUEST_FAILED, ACTIVATE_DEVELOPER_MODE, UNIVERSAL_APP_IS_READY, TOGGLE_APPLY_CHANGES_MODAL_OPEN } from 'ContentEditorUI/redux/actions/actionTypes';
import { CLOSE_RICH_TEXT_EDITOR_FIREFOX, OPEN_RICH_TEXT_EDITOR_FIREFOX } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import { Map as ImmutableMap } from 'immutable';
import { isLocked } from 'ContentEditorUI/utils/errorUtils';

const AppStatus = (state = ImmutableMap({
  hasManuallySetLoadingStatus: false,
  i18nReady: false,
  caresAboutNextIframeClick: false,
  iframeStartedLoading: null,
  iframeReady: false,
  iframeRendered: false,
  iframeHidden: true,
  addModuleSidebarLoaded: false,
  iframeCaresAboutNextWindowClick: false,
  exitPreviewAndLayoutButtonDisplayed: false,
  saveError: null,
  routingInProgress: null,
  developerMode: false,
  isUniversalAppReady: false
}), action) => {
  switch (action.type) {
    case SHOW_LOADING:
    case RELOAD_WITHOUT_WARNING:
      return state.set('hasManuallySetLoadingStatus', true);

    case I18N_READY:
      return state.set('i18nReady', true);

    case SET_IFRAME_READY:
      return state.merge({
        iframeReady: true
      });

    case SET_IFRAME_RENDERED:
      return state.merge({
        iframeReady: true,
        iframeRendered: true
      });

    case SET_IFRAME_LOADING:
      {
        const {
          startedLoadingAt
        } = action.payload;
        return state.merge({
          iframeReady: false,
          iframeStartedLoadingAt: startedLoadingAt
        });
      }

    case SET_IFRAME_HIDDEN:
      {
        const {
          iframeHidden
        } = action.payload;
        return state.merge({
          iframeHidden
        });
      }

    case SET_ADD_MODULE_SIDEBAR_LOADED:
      return state.merge({
        addModuleSidebarLoaded: true
      });

    case HIDE_LOADING:
      return state.set('hasManuallySetLoadingStatus', false);

    case IFRAME_CLICKED:
      return state.set('caresAboutNextIframeClick', false);

    case SET_VALIDATION_POPOVER_OPEN:
    case OPEN_POPOVER:
      return state.set('caresAboutNextIframeClick', true);

    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        const payload = action.payload;
        const routingInProgressOpts = payload ? payload.routingInProgress : null;
        return state.merge({
          routingInProgress: routingInProgressOpts
        });
      }

    case OPEN_RICH_TEXT_EDITOR_FIREFOX:
      return state.set('iframeCaresAboutNextWindowClick', true);

    case CLOSE_RICH_TEXT_EDITOR_FIREFOX:
      return state.set('iframeCaresAboutNextWindowClick', false);

    case DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON:
      return state.merge({
        exitPreviewAndLayoutButtonDisplayed: true
      });

    case CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON:
      return state.merge({
        exitPreviewAndLayoutButtonDisplayed: false
      });

    case CONTENT_SAVE_REQUEST_FAILED:
      {
        const {
          error: {
            response
          }
        } = action;

        if (isLocked(response)) {
          return state.merge({
            saveError: response.responseJSON || {}
          });
        }

        return state;
      }

    case ACTIVATE_DEVELOPER_MODE:
      return state.merge({
        developerMode: true
      });

    case UNIVERSAL_APP_IS_READY:
      {
        return state.set('isUniversalAppReady', true);
      }

    default:
      return state;
  }
};

export default AppStatus;