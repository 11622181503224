const getImageModuleId = img => {
  let wrapperElement = img.closest('.hs_cos_wrapper');

  if (!wrapperElement || wrapperElement.id.includes('custom_module')) {
    return null;
  }

  while (wrapperElement.parentElement) {
    const parentElement = wrapperElement.parentElement;

    if (!parentElement.classList.contains('hs_cos_wrapper')) {
      break;
    } else {
      wrapperElement = parentElement;
    }
  }

  const elementId = wrapperElement.id;
  return elementId && elementId.split('hs_cos_wrapper_').pop();
};

const getGlobalResourcePath = img => {
  const globalWrapper = img.closest('[data-global-resource-path]');
  return globalWrapper && globalWrapper.dataset.globalResourcePath;
};

export const getImages = (userDOM, setGathererArtifact) => {
  const images = userDOM.querySelectorAll('img');
  const normalizedImages = images && [...images].map(img => {
    const {
      src,
      alt,
      role
    } = img;
    const moduleId = getImageModuleId(img);
    const globalResourcePath = getGlobalResourcePath(img);
    return {
      src,
      alt,
      role,
      moduleId,
      globalResourcePath
    };
  });
  setGathererArtifact('images', normalizedImages);
};