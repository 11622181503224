import { ApprovalStatus } from '../types/ApprovalStatus';
export const shouldRenderChildren = approval => {
  // Checking enabled is explicitly false as the property is null until the api call resolves
  if (approval.config.enabled === false) {
    return true;
  }

  const isApproved = approval.meta.approvalStatus === ApprovalStatus.APPROVED;
  const isConcluded = approval.data && approval.data.approvalConcluded;
  const restartOnConclude = approval.meta.restartOnConclude;

  if (isApproved && restartOnConclude && isConcluded) {
    return false;
  }

  return isApproved;
};