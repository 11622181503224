import Raven from 'raven-js';
import enviro from 'enviro';

const debugLog = (...message) => {
  if (enviro.debug('CMS_WOOTRIC_SURVEY')) {
    console.log(...message);
  }
};

function isWootricLoaded(window) {
  return typeof window.wootric === 'function';
}

export const triggerWootricSurvey = () => {
  try {
    window.wootric('run');
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        from: 'triggerWootricSurvey'
      }
    });
  }
};
export const MAX_ATTEMPTS = 10;
const DELAY_MILLISECONDS = 2 * 1000; // Show the CMS wootric survey (via global that comes from nav), but use retries to try and wait until
// the side-loaded Wootric JS is available

export const triggerWootricSurveyWhenAvailable = ({
  attemptNum = 1,
  // Some helpers for testing
  disableRetries = false,
  triggerSurveyFunc = triggerWootricSurvey
} = {}) => {
  if (attemptNum <= MAX_ATTEMPTS) {
    if (isWootricLoaded(window)) {
      debugLog('Wootric global is available, triggering wootric survey');
      triggerSurveyFunc();
    } else if (!disableRetries) {
      debugLog(`Wootric global is not yet available, retrying in ${DELAY_MILLISECONDS / 1000} seconds (attemptNum = ${attemptNum})`);
      setTimeout(() => {
        triggerWootricSurveyWhenAvailable({
          attemptNum: attemptNum + 1,
          triggerSurveyFunc
        });
      }, DELAY_MILLISECONDS);
    }
  }
};