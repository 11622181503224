// Labels can be found in ContentUtils/lang
const TemplateTypes = [{
  categoryId: 0,
  id: 0,
  isForContent: false,
  isGroup: false,
  name: 'unmapped',
  requiredModules: []
}, {
  categoryId: 2,
  id: 1,
  isForContent: true,
  isGroup: false,
  name: 'email_base_template',
  requiredModules: []
}, {
  categoryId: 2,
  id: 2,
  isForContent: true,
  isGroup: false,
  name: 'email',
  requiredModules: []
}, {
  categoryId: 1,
  id: 3,
  isForContent: true,
  isGroup: false,
  name: 'landing_page_base_template',
  requiredModules: []
}, {
  categoryId: 1,
  id: 4,
  isForContent: true,
  isGroup: false,
  name: 'landing_page',
  requiredModules: []
}, {
  categoryId: 3,
  id: 5,
  isForContent: true,
  isGroup: false,
  name: 'blog_base',
  requiredModules: []
}, {
  categoryId: 3,
  id: 6,
  isForContent: true,
  isGroup: false,
  name: 'blog',
  requiredModules: []
}, {
  categoryId: 3,
  id: 42,
  isForContent: true,
  isGroup: true,
  name: 'blog_listing',
  requiredModules: []
}, {
  categoryId: 4,
  id: 8,
  isForContent: true,
  isGroup: false,
  name: 'site_page',
  requiredModules: []
}, {
  categoryId: 3,
  id: 9,
  isForContent: true,
  isGroup: true,
  name: 'blog_listing_context',
  requiredModules: []
}, {
  categoryId: 3,
  id: 10,
  isForContent: true,
  isGroup: false,
  name: 'blog_post_context',
  requiredModules: []
}, {
  categoryId: 0,
  id: 11,
  isForContent: false,
  isGroup: false,
  name: 'error_page',
  requiredModules: []
}, {
  categoryId: 0,
  id: 12,
  isForContent: false,
  isGroup: false,
  name: 'subscription_preferences',
  requiredModules: ['email_subscriptions']
}, {
  categoryId: 0,
  id: 13,
  isForContent: false,
  isGroup: false,
  name: 'unsubscribe_confirmation',
  requiredModules: ['email_subscriptions_confirmation']
}, {
  categoryId: 0,
  id: 14,
  isForContent: false,
  isGroup: false,
  name: 'unsubscribe_simple',
  requiredModules: ['email_simple_subscription']
}, {
  categoryId: 2,
  id: 15,
  isForContent: true,
  isGroup: false,
  name: 'optin_email',
  requiredModules: ['email_optin']
}, {
  categoryId: 2,
  id: 16,
  isForContent: true,
  isGroup: false,
  name: 'optin_followup_email',
  requiredModules: []
}, {
  categoryId: 1,
  id: 17,
  isForContent: true,
  isGroup: false,
  name: 'optin_confirmation_page',
  requiredModules: []
}, {
  categoryId: 1,
  id: 18,
  isForContent: false,
  isGroup: false,
  name: 'global_group',
  requiredModules: []
}, {
  categoryId: 0,
  id: 19,
  isForContent: false,
  isGroup: false,
  name: 'password_prompt_page',
  requiredModules: []
}, {
  categoryId: 2,
  id: 20,
  isForContent: true,
  isGroup: false,
  name: 'resubscribe_email',
  requiredModules: ['email_resubscribe']
}, {
  categoryId: 2,
  id: 21,
  isForContent: true,
  isGroup: false,
  name: 'unsubscribe_confirmation_email',
  requiredModules: ['email_unsubscribe_confirmation']
}, {
  categoryId: 2,
  id: 22,
  isForContent: true,
  isGroup: false,
  name: 'resubscribe_confirmation_email',
  requiredModules: ['email_resubscribe_confirmation']
}, {
  categoryId: 0,
  id: 23,
  isForContent: false,
  isGroup: false,
  name: 'custom_module',
  requiredModules: []
}, {
  categoryId: 0,
  id: 24,
  isForContent: false,
  isGroup: false,
  name: 'css',
  requiredModules: []
}, {
  categoryId: 0,
  id: 25,
  isForContent: false,
  isGroup: false,
  name: 'js',
  requiredModules: []
}, {
  categoryId: 0,
  id: 27,
  isForContent: false,
  isGroup: false,
  name: 'search_results',
  requiredModules: []
}, {
  categoryId: 0,
  id: 29,
  isForContent: false,
  isGroup: false,
  name: 'membership_login',
  requiredModules: []
}, {
  categoryId: 0,
  id: 30,
  isForContent: false,
  isGroup: false,
  name: 'membership_register',
  requiredModules: []
}, {
  categoryId: 0,
  id: 31,
  isForContent: false,
  isGroup: false,
  name: 'membership_reset',
  requiredModules: []
}, {
  categoryId: 0,
  id: 32,
  isForContent: false,
  isGroup: false,
  name: 'membership_reset_request',
  requiredModules: []
}, {
  categoryId: 2,
  id: 34,
  isForContent: true,
  name: 'drag_drop_email'
}, {
  id: 35,
  name: 'knowledge_article',
  categoryId: 6,
  isForContent: true
}, {
  id: 36,
  categoryId: 0,
  name: 'membership_email',
  isForContent: true
}, {
  id: 37,
  categoryId: 0,
  name: 'section',
  isForContent: false
}, {
  id: 38,
  categoryId: 0,
  name: 'global_content_partial',
  isForContent: false
}, {
  categoryId: 1,
  id: 39,
  isForContent: true,
  isGroup: false,
  name: 'simple_landing_page_template',
  requiredModules: []
}, {
  categoryId: 0,
  id: 40,
  isForContent: false,
  isGroup: false,
  name: 'proposal',
  requiredModules: []
}, {
  categoryId: 3,
  id: 41,
  isForContent: true,
  isGroup: false,
  name: 'blog_post',
  requiredModules: []
}, {
  categoryId: 0,
  id: 43,
  isForContent: false,
  isGroup: false,
  name: 'quote',
  requiredModules: []
}, {
  categoryId: 8,
  id: 44,
  isForContent: true,
  isGroup: false,
  name: 'web_interactive',
  requiredModules: []
}, {
  categoryId: 0,
  id: 45,
  isForContent: false,
  isGroup: false,
  name: 'quote_expiration',
  requiredModules: []
}, {
  id: 46,
  categoryId: 0,
  name: 'fixed_layout_section',
  isForContent: false
}, {
  categoryId: 10,
  id: 47,
  isForContent: true,
  isGroup: false,
  name: 'sms',
  requiredModules: []
}];
const templateTypes = Object.freeze(TemplateTypes.reduce((hash, templateType) => {
  hash.byId[templateType.id] = templateType;
  hash.byName[templateType.name] = templateType;
  return hash;
}, {
  byId: {},
  byName: {}
}));
export const byId = templateTypes.byId;
export const byName = templateTypes.byName;
export default templateTypes;