import { selectContentModelState } from '../utils/editorStateSelectors';
export const getMetaTags = (userDOM, setGathererArtifact, editorState) => {
  const metaTagsHTML = userDOM.querySelectorAll('head meta');
  const metaTags = metaTagsHTML && [...metaTagsHTML].map(metaTag => {
    const {
      name,
      content
    } = metaTag;
    return {
      name,
      content
    };
  });
  const contentModelState = selectContentModelState(editorState);
  const metaDescriptionContent = contentModelState && contentModelState.metaDescription;
  const metaDescription = metaTags.find(metaTag => metaTag.name === 'description');

  if (metaDescription) {
    metaDescription.content = metaDescriptionContent;
  } else {
    metaTags.push({
      name: 'description',
      content: metaDescriptionContent
    });
  }

  setGathererArtifact('metaTags', metaTags);
};