'use es6';

import { JS_OBJECT, PRIMITIVE_JS_VALUE, IMMUTABLE_MAP, IMMUTABLE_SET, IMMUTABLE_MAP_OF_LAYOUT_TREES, ALLOWLISTED_AUTH_OBJECT, NestedImmutableMap, IMMUTABLE_LIST } from './types';
/**
 * Config describing which slices of app state to sync with the inpage app
 * and any transformations or special handling that should be applied
 */

export default {
  appStatus: IMMUTABLE_MAP,
  auth: ALLOWLISTED_AUTH_OBJECT,
  brandSettings: IMMUTABLE_MAP,
  categorizations: JS_OBJECT,
  contentAssets: JS_OBJECT,
  contentModel: NestedImmutableMap({
    themeSettingsValues: JS_OBJECT,
    id: PRIMITIVE_JS_VALUE,
    htmlTitle: PRIMITIVE_JS_VALUE,
    metaDescription: PRIMITIVE_JS_VALUE
  }),
  styleBreakpoints: NestedImmutableMap({
    activeBreakpointName: PRIMITIVE_JS_VALUE
  }),
  contentReadOnlyData: IMMUTABLE_MAP,
  editSidebar: IMMUTABLE_MAP,
  iframes: JS_OBJECT,
  inpageData: NestedImmutableMap({
    highlightedWidgetId: PRIMITIVE_JS_VALUE,
    highlightedWidgetContainerId: PRIMITIVE_JS_VALUE,
    iframeNeedsRefresh: PRIMITIVE_JS_VALUE,
    mouseCoordsAndInfoFromLastBlur: JS_OBJECT,
    html: PRIMITIVE_JS_VALUE,
    htmlBody: PRIMITIVE_JS_VALUE,
    inpageMode: PRIMITIVE_JS_VALUE,
    isDraggingModule: PRIMITIVE_JS_VALUE,
    scrollToModuleId: PRIMITIVE_JS_VALUE,
    scrollToNodePositionInfo: JS_OBJECT,
    computedStylesBySelector: IMMUTABLE_MAP,
    previewViewportDimensions: IMMUTABLE_MAP,
    useScrollIfModuleOutOfBoundsAtAll: PRIMITIVE_JS_VALUE,
    nopeZoneSelector: PRIMITIVE_JS_VALUE
  }),
  inpageOptimizationState: JS_OBJECT,
  instanceEditing: JS_OBJECT,
  header: NestedImmutableMap({
    activeItem: PRIMITIVE_JS_VALUE,
    shouldClear: PRIMITIVE_JS_VALUE
  }),
  languages: IMMUTABLE_MAP,
  locallyCachedSettings: IMMUTABLE_MAP,
  contentTypeLimit: JS_OBJECT,
  modals: IMMUTABLE_MAP,
  moduleSchemas: JS_OBJECT,
  modules: {
    immutable: true,
    allowlist: ['present', 'redoCount', 'undoCount'],
    nestedConfig: {
      redoCount: PRIMITIVE_JS_VALUE,
      undoCount: PRIMITIVE_JS_VALUE,
      present: NestedImmutableMap({
        uneditableWidgets: IMMUTABLE_MAP,
        schemaLayoutSectionTrees: IMMUTABLE_MAP_OF_LAYOUT_TREES,
        layoutSectionTrees: IMMUTABLE_MAP_OF_LAYOUT_TREES,
        layoutSectionWidgets: IMMUTABLE_MAP,
        schemaWidgetContainers: IMMUTABLE_MAP,
        widgetContainers: IMMUTABLE_MAP,
        schemaWidgets: IMMUTABLE_MAP,
        fakeModules: IMMUTABLE_MAP,
        widgets: IMMUTABLE_MAP,
        contentCss: IMMUTABLE_MAP,
        moduleMetaData: IMMUTABLE_MAP,
        clonedModulesPendingDomOperations: IMMUTABLE_SET,
        themePageOverrides: JS_OBJECT
      })
    }
  },
  optins: JS_OBJECT,
  onboardingTourState: IMMUTABLE_MAP,
  requestStates: JS_OBJECT,
  saveData: IMMUTABLE_MAP,
  selection: IMMUTABLE_MAP,
  sidebarState: IMMUTABLE_MAP,
  smartView: IMMUTABLE_MAP,
  templateInfo: {
    immutable: true,
    allowlist: ['templateType', 'customSections', 'marketerSectionsCount', 'marketerSectionsLimit'],
    nestedConfig: {
      customSections: PRIMITIVE_JS_VALUE,
      marketerSectionsCount: PRIMITIVE_JS_VALUE,
      marketerSectionsLimit: PRIMITIVE_JS_VALUE
    }
  },
  testFlags: IMMUTABLE_MAP,
  theme: NestedImmutableMap({
    customFonts: PRIMITIVE_JS_VALUE,
    hasTheme: PRIMITIVE_JS_VALUE,
    hasThemeSettings: PRIMITIVE_JS_VALUE,
    theme: IMMUTABLE_MAP,
    overriddenThemeSettings: IMMUTABLE_LIST
  })
};