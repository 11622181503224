const validTours = {
  'contact-tour': true,
  contacts: true,
  'contacts-import': true,
  'create-contact': true,
  'create-contact-from-segmentation': true,
  'embedded-comments-redesign': true,
  'edit-theme-settings': true,
  'create-custom-record-property-1': true,
  'create-custom-record-property-2': true,
  'create-custom-record-property-3': true,
  'create-custom-record-property-4': true,
  'create-custom-record-property-interactive-1': true,
  'create-custom-record-property-interactive-2': true,
  'create-custom-record-property-interactive-3': true,
  'create-custom-record-property-interactive-4': true,
  'contacts-and-contact-record-explore-1': true,
  'contacts-and-contact-record-explore-2': true,
  'create-deal-1': true,
  'create-deal-2': true,
  'create-email-1': true,
  'create-email-2-with-send': true,
  'create-email-3': true,
  'create-task': true,
  'create-embedded-form-from-type-page': true,
  'create-embedded-form-from-type-page-starter': true,
  'edit-deal-stages': true,
  'edit-deal-stages-extended': true,
  'filter-contacts': true,
  'free-lp-onboarding-1': true,
  'free-lp-onboarding-2': true,
  'free-lp-onboarding-3': true,
  'free-lp-onboarding-4': true,
  'go-to-getting-started': true,
  'import-tour': true,
  'simplified-task-list-tour': true,
  'simplified-service-hub-task-list-tour': true,
  'new-contact-tour': true,
  profile: true,
  'publish-and-edit-content': true,
  'publish-and-edit-editor': true,
  'quick-import': true,
  test: true,
  'academy-tour': true,
  'nav-tour': true,
  'new-contact-record': true,
  'imported-contacts-post-signup': true,
  'create-email-list-with-filter': true,
  'control-center-custom-views-pages': true,
  'control-center-custom-views-blog-posts': true,
  'control-center-pages-revamp-no-custom-views': true,
  'control-center-blog-posts-revamp-no-custom-views': true,
  'cms-approval-status-column': true,
  'editor-language-variation-selector-moved': true,
  'skip-data-sync-setup': true,
  'data-sync-setup-not-complete': true,
  'data-sync-setup-complete': true,
  'data-sync-flow': true,
  'data-sync-contacts': true,
  'listing-lib': true,
  'listing-lib-folders': true,
  'campaign-templates': true,
  'campaign-templates-editor': true,
  'select-sales-email-template': true,
  'reporting-dashboards': true,
  'create-sales-reporting-dashboard': true,
  'create-marketing-reporting-dashboard': true,
  'rename-deal-pipeline': true,
  'floating-sidebar': true,
  'explore-payments-tool': true,
  'discover-more-ways-to-get-paid': true,
  'resolvable-comments': true,
  'object-lists-views': true,
  'sales-reporting': true
};
export default validTours;