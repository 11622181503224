import { removeDomainFromTitleAudit } from '../recommendations/removeDomainFromTitle/removeDomainFromTitleAudit';
import { GathererKeys } from 'xray-recommendation-lib/gatherers/allGatherers';
import { Sections } from '../../constants';
import { addTitleAudit } from '../recommendations/addTitle/addTitleAudit';
import { shortenTitleAudit } from '../recommendations/shortenTitle/shortenTitleAudit';
import { ShortenTitleView } from '../recommendations/shortenTitle/ShortenTitleView';
import { RemoveDomainFromTitleView } from '../recommendations/removeDomainFromTitle/RemoveDomainFromTitleView';
import { AddTitleView } from '../recommendations/addTitle/AddTitleView';
import { addMetaDescriptionAudit } from '../recommendations/addMetaDescription/addMetaDescriptionAudit';
import { AddMetaDescriptionView } from '../recommendations/addMetaDescription/AddMetaDescriptionView';
import { removeTitleFromMetaDescriptionAudit } from '../recommendations/removeTitleFromMetaDescription/removeTitleFromMetaDescriptionAudit';
import { RemoveTitleFromMetaDescriptionView } from '../recommendations/removeTitleFromMetaDescription/RemoveTitleFromMetaDescriptionView';
import { shortenMetaDescriptionAudit } from '../recommendations/shortenMetaDescription/shortenMetaDescriptionAudit';
import { ShortenMetaDescriptionView } from '../recommendations/shortenMetaDescription/ShortenMetaDescriptionView';
import { GroupView } from '../sharedComponents/GroupView';
import { SeoRecommendationGroups, SeoRecommendations } from '../constants';
import { addH1TagsAudit } from '../recommendations/addH1Tags/addH1TagsAudit';
import { AddH1TagsView } from '../recommendations/addH1Tags/AddH1TagsView';
import { addImageAltTextAudit } from '../recommendations/addImageAltText/addImageAltTextAudit';
import { AddImageAltTextView } from '../recommendations/addImageAltText/AddImageAltTextView';
import { verifyEmptyImageAltTextAudit } from '../recommendations/verifyEmptyImageAltText/verifyEmptyImageAltTextAudit';
import { VerifyEmptyImageAltTextView } from '../recommendations/verifyEmptyImageAltText/VerifyEmptyImageAltTextView';
import { addViewportTagAudit } from '../recommendations/addViewportTag/addViewportTagAudit';
import { AddViewportTagView } from '../recommendations/addViewportTag/AddViewportTagView';
import { decreaseOutboundLinkCountAudit } from '../recommendations/decreaseOutboundLinkCount/decreaseOutboundLinkCountAudit';
import { DecreaseOutboundLinkCountView } from '../recommendations/decreaseOutboundLinkCount/DecreaseOutboundLinkCountView';
import { makePageIndexableBySearchEnginesAudit } from '../recommendations/makePageIndexableBySearchEngines/makePageIndexableBySearchEnginesAudit';
import { MakePageIndexableBySearchEnginesView } from '../recommendations/makePageIndexableBySearchEngines/MakePageIndexableBySearchEnginesView';
import { increaseWordCountAudit } from '../recommendations/increaseWordCount/increaseWordCountAudit';
import { IncreaseWordCountView } from '../recommendations/increaseWordCount/IncreaseWordCountView';
export const getSeoSectionConfig = editorApi => {
  const addH1Tags = {
    type: SeoRecommendations.ADD_H1_TAGS,
    audit: addH1TagsAudit,
    view: AddH1TagsView
  };
  const addImageAltText = {
    type: SeoRecommendations.ADD_IMAGE_ALT_TEXT,
    audit: addImageAltTextAudit,
    view: AddImageAltTextView,
    action: editorApi.openImageModule
  };
  const addMetaDescription = {
    type: SeoRecommendations.ADD_META_DESCRIPTION,
    audit: addMetaDescriptionAudit,
    view: AddMetaDescriptionView,
    action: editorApi.addMetaDescription
  };
  const addTitle = {
    type: SeoRecommendations.ADD_TITLE,
    audit: addTitleAudit,
    view: AddTitleView,
    action: editorApi.addPageTitle
  };
  const addViewportTag = {
    type: SeoRecommendations.ADD_VIEWPORT_TAG,
    audit: addViewportTagAudit,
    view: AddViewportTagView
  };
  const decreaseOutboundLinkCount = {
    type: SeoRecommendations.DECREASE_OUTBOUND_LINK_COUNT,
    audit: decreaseOutboundLinkCountAudit,
    view: DecreaseOutboundLinkCountView
  };
  const increaseWordCount = {
    type: SeoRecommendations.INCREASE_WORD_COUNT,
    audit: increaseWordCountAudit,
    view: IncreaseWordCountView
  };
  const makePageIndexableBySearchEngines = {
    type: SeoRecommendations.MAKE_PAGE_INDEXABLE_BY_SEARCH_ENGINES,
    audit: makePageIndexableBySearchEnginesAudit,
    view: MakePageIndexableBySearchEnginesView
  };
  const removeDomainFromTitle = {
    type: SeoRecommendations.REMOVE_DOMAIN_FROM_TITLE,
    audit: removeDomainFromTitleAudit,
    view: RemoveDomainFromTitleView,
    action: editorApi.removeDomainFromTitle
  };
  const removeTitleFromMetaDescription = {
    type: SeoRecommendations.REMOVE_TITLE_FROM_META_DESCRIPTION,
    audit: removeTitleFromMetaDescriptionAudit,
    view: RemoveTitleFromMetaDescriptionView,
    action: editorApi.removeTitleFromMetaDescription
  };
  const shortenMetaDescription = {
    type: SeoRecommendations.SHORTEN_META_DESCRIPTION,
    audit: shortenMetaDescriptionAudit,
    view: ShortenMetaDescriptionView,
    action: editorApi.shortenMetaDescription
  };
  const shortenTitle = {
    type: SeoRecommendations.SHORTEN_TITLE,
    audit: shortenTitleAudit,
    view: ShortenTitleView,
    action: editorApi.shortenPageTitle
  };
  const verifyEmptyImageAltText = {
    type: SeoRecommendations.VERIFY_EMPTY_IMAGE_ALT_TEXT,
    audit: verifyEmptyImageAltTextAudit,
    view: VerifyEmptyImageAltTextView,
    action: editorApi.openImageModule
  };
  const crawlingAndIndexingGroup = {
    type: SeoRecommendationGroups.CRAWLING_AND_INDEXING,
    gatherers: [GathererKeys.META_TAGS],
    view: GroupView,
    recs: [makePageIndexableBySearchEngines]
  };
  const contentGroup = {
    type: SeoRecommendationGroups.CONTENT,
    gatherers: [GathererKeys.POST_BODY],
    view: GroupView,
    recs: [increaseWordCount]
  };
  const titleGroup = {
    type: SeoRecommendationGroups.TITLE,
    gatherers: [GathererKeys.PAGE_TITLE],
    view: GroupView,
    recs: [removeDomainFromTitle, addTitle, shortenTitle]
  };
  const linksGroup = {
    type: SeoRecommendationGroups.LINKS,
    gatherers: [GathererKeys.LINKS],
    view: GroupView,
    recs: [decreaseOutboundLinkCount]
  };
  const metaDescriptionGroup = {
    type: SeoRecommendationGroups.META_DESCRIPTION,
    gatherers: [GathererKeys.META_TAGS, GathererKeys.PAGE_TITLE],
    view: GroupView,
    recs: [addMetaDescription, removeTitleFromMetaDescription, shortenMetaDescription]
  };
  const mobileFriendlinessGroup = {
    type: SeoRecommendationGroups.MOBILE_FRIENDLINESS,
    gatherers: [GathererKeys.META_TAGS],
    view: GroupView,
    recs: [addViewportTag]
  };
  const headerGroup = {
    type: SeoRecommendationGroups.HEADER,
    gatherers: [GathererKeys.H1Count],
    view: GroupView,
    recs: [addH1Tags]
  };
  const imagesGroup = {
    type: SeoRecommendationGroups.IMAGES,
    gatherers: [GathererKeys.IMAGES],
    view: GroupView,
    recs: [addImageAltText, verifyEmptyImageAltText]
  };
  const seoSectionConfig = {
    sectionType: Sections.SEO,
    groups: [titleGroup, metaDescriptionGroup, headerGroup, imagesGroup, linksGroup, mobileFriendlinessGroup, crawlingAndIndexingGroup, contentGroup]
  };
  return seoSectionConfig;
};