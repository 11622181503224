import { QUANTITY_PACK } from 'self-service-api/constants/SkuTypes';
import { getProductInfoFromSkuId, getSkuMap } from 'self-service-api/core/utilities/productUtils';

const getQuantityPackFromSku = (products, skuId) => {
  let quantityPackDetails = null;
  Object.values(products).forEach(product => {
    product.productVersions.forEach(productVersion => {
      productVersion.quantityPackDetails.forEach(quantityPack => {
        if (quantityPack.skuId === skuId) {
          quantityPackDetails = quantityPack;
        }
      });
    });
  });
  return quantityPackDetails;
};

const getProductVersionFromSkuId = (products, skuId) => {
  const productInfo = getProductInfoFromSkuId(products, skuId);
  return productInfo ? productInfo.productVersions.find(productVersion => productVersion.skuId === skuId) : null;
};

export const getProductTrackingName = (products, skuId) => {
  if (!products || !skuId) return null;
  const productVersion = getProductVersionFromSkuId(products, skuId);
  const quantityPack = getQuantityPackFromSku(products, skuId);

  if (productVersion || quantityPack) {
    return productVersion ? productVersion.displayName : quantityPack.displayName;
  }

  return null;
};
export const getIsQuantityUpgrade = (products, productConfigurations = []) => {
  if (productConfigurations.length !== 1 || !products) return false;
  const skuMap = getSkuMap(products);
  const skuId = productConfigurations[0].skuId;
  return skuMap[skuId].skuType === QUANTITY_PACK;
};
export const getIsMultipleOfferQuantityUpgrade = (products, offerProductConfigurations) => {
  if (!products) return false;
  return offerProductConfigurations.map(({
    productConfigurations
  }) => productConfigurations).flat().every(({
    skuId
  }) => {
    const skuMap = getSkuMap(products);
    return skuMap[skuId].skuType === QUANTITY_PACK;
  });
};
export const getSkuIdFromApiName = (products, name) => {
  const productInfo = products.find(({
    apiName
  }) => apiName === name);

  if (!productInfo) {
    return null;
  }

  return productInfo.currentSkuId;
};
export const getFilteredProductConfigurationsByChargeType = (products, productConfigurations, chargeType) => {
  return productConfigurations.filter(({
    skuId
  }) => {
    const productInfo = getProductInfoFromSkuId(products, skuId);
    return productInfo && productInfo.chargeType === chargeType;
  }).map(({
    skuId,
    quantity
  }) => {
    const skuMap = getSkuMap(products);
    const skuInfo = skuMap[skuId];

    if (skuInfo.skuType === QUANTITY_PACK) {
      const {
        includedWithQuantityPack
      } = skuInfo.quantityUnit;
      const calculatedQuantity = Math.ceil(quantity / includedWithQuantityPack);
      return {
        skuId,
        quantity: calculatedQuantity
      };
    }

    return {
      skuId,
      quantity
    };
  });
};