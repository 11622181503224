import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { CREATE_DEFAULTS, FETCH_DEFAULTS } from 'ContentData/constants/apiDefaults';
import Routes from 'ContentUtils/Routes';

/**
 * GET automation/v3/workflows
 * NOTE: This endpoint is not paginated
 */
export const fetchWorkflows = (...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('automation/v3/workflows', Object.assign({}, FETCH_DEFAULTS.workflows));
};
/**
 * GET automation/v3/workflows/{id}
 */

export const fetchWorkflow = (id, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`automation/v3/workflows/${id}`);
};
/* POST GET automation/v3/workflows */

export const createWorkflow = (name, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('automation/v3/workflows', {
    data: Object.assign({}, CREATE_DEFAULTS.workflows, {
      name
    })
  });
};
/* POST content-workflows/v1/followup-email/fetch */

/*
Use this content-workflows API endpoint because it internally calls the Automation API and
will bypass workflows-access scope check. This way, users without workflows-access can
still create/delete/fetch their followup email workflows
*/

export const fetchHybridWorkflowsBatch = (flowIds = [], ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('content-workflows/v1/followup-email/fetch', {
    data: flowIds
  });
};
/**
 * POST crm-usages/v2/usages/form/${formTypeId}-${formId}/parents/flow_action
 */

export const fetchFormWorkflowDependencies = (formId, formTypeId, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`crm-usages/v2/usages/form/${formTypeId}-${formId}/parents/flow_action`);
};
/**
 * GET automationapps/v1/summaries
 */

export const fetchWorkflowSummaries = (query, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('automationapps/v1/summaries', {
    query: Object.assign({
      flowObjectType: 'CONTACT'
    }, query)
  });
};

const getSourceApp = () => {
  return Routes.isUngated('ContextualAutomation:FollowupEmailInFormField') ? 'CMS_FORM_MODULE_FIELD' : 'FORM_FIELD_FOLLOWUP';
};
/* POST automationplatform/v1/hybrid/batch */


export const updateWorkflowHybrid = (workflowHybrid, sourceApp = getSourceApp(), ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('automationplatform/v1/hybrid/batch', {
    query: {
      sourceApp
    },
    data: workflowHybrid
  });
};
/* POST automationplatform/v1/hybrid/create */

export const createWorkflowHybrid = (workflowHybrid, contentId, sourceApp = getSourceApp(), ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('automationplatform/v1/hybrid/create', {
    query: {
      sourceApp
    },
    data: workflowHybrid,
    headers: {
      'x-hs-referer': Routes.edit(contentId)
    }
  });
};
/* POST content-workflows/v1/followup-email/create */

export const createFollowupEmailWorkflow = (formId, contentId, followUpEmailId, workflowName, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('content-workflows/v1/followup-email/create', {
    query: {
      clienttimeout: 20000
    },
    data: {
      formId,
      pageId: contentId,
      emailId: followUpEmailId,
      name: workflowName
    },
    headers: {
      'x-hs-referer': Routes.edit(contentId)
    }
  });
};
/* PUT content-workflows/v1/followup-email/update */

export const updateFollowupEmailWorkflow = (flowId, followUpEmailId, workflowName, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put('content-workflows/v1/followup-email/update', {
    data: {
      flowId,
      emailId: followUpEmailId,
      name: workflowName
    }
  });
};
/* DELETE automationplatform/v1/hybrid/{flowId} */

export const deleteWorkflowHybrid = (flowId, sourceApp, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.delete(`automationplatform/v1/hybrid/${flowId}`, {
    query: {
      sourceApp
    }
  });
};
/* DELETE content-workflows/v1/followup-email/delete */

export const deleteFollowupEmailWorkflow = (flowId, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.delete('content-workflows/v1/followup-email/delete', {
    data: {
      flowId
    }
  });
};
/* POST automationplatform/v1/hybrid/references */

export const fetchWorkflowReferences = (flowId, ...rest) => {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('automationplatform/v1/hybrid/references', {
    data: {
      flows: [{
        flowId,
        flowSource: 'PLATFORM'
      }],
      includeOutboundReferences: false
    }
  });
};