export const getLinks = (userDOM, setGathererArtifact) => {
  const linksElements = userDOM.querySelectorAll('body a');
  const links = linksElements && [...linksElements].map(link => {
    const {
      href,
      hreflang,
      rel,
      text
    } = link;
    return {
      href,
      hreflang,
      rel,
      text
    };
  });
  setGathererArtifact('links', links);
};