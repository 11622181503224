'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from './helpers';
import { getHasCtaModulesWithoutAccess, getHasHsVideoModulesWithoutAccess, getContentHasPaymentsFieldWithoutAccess, getContentHasPersonalizationTokensWithoutAccess, getHasSmartContentWithoutAccess } from 'ContentEditorUI/redux/selectors/downgradeSelectors';
import { getIsAbTestWithoutAccess } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getHasSeenScopesHaveChangedModal } from 'ContentEditorUI/redux/selectors/settingsSelectors';
import { getIsMABExperimentWithoutAccess } from 'ContentEditorUI/redux/selectors/mabExperimentSelectors';
const getModalInfo = basicSelector(state => state.modals);

const createModalInfoSelector = key => createSelector([getModalInfo], modalInfo => modalInfo.get(key));

export const getOpenModal = createModalInfoSelector('openModal');
export const getOpenModalMetadata = createSelector([getOpenModal, getModalInfo], (openModal, modalInfo) => !!openModal && modalInfo.get('modalMetadata'));
export const getOpenPopOutEditorKey = createModalInfoSelector('openPopOutEditorKey');
export const getHasOpenPopOutEditor = createSelector([getOpenPopOutEditorKey], openPopOutEditorKey => !!openPopOutEditorKey && openPopOutEditorKey.length > 0);
export const getPublishModalOpen = createModalInfoSelector('publishModalOpen');
export const isPublishModalOpen = createSelector([getPublishModalOpen], publishModalOpenStatus => !!publishModalOpenStatus);
export const getIsPublishOptionsModalOpen = createModalInfoSelector('publishOptionsModalOpen');
export const isApplyChangesModalOpen = createModalInfoSelector('applyChangesModalOpen');
export const getEditTemplateModalIsOpen = createModalInfoSelector('editTemplateModalIsOpen');
export const getHasScopesHaveChangedModalBeenDismissed = createModalInfoSelector('scopesHaveChangedModalWasDismissed');
const getHasAdvancedContentWithoutAccess = createSelector([getHasCtaModulesWithoutAccess, getHasHsVideoModulesWithoutAccess, getIsAbTestWithoutAccess, getHasSmartContentWithoutAccess, getIsMABExperimentWithoutAccess], (hasCtasModulesWithoutAccess, hasHsVideoModulesWithoutAccess, isABTestWithoutAccess, hasSmartContentWithoutAccess, isMABExperimentWithoutAccess) => hasCtasModulesWithoutAccess || hasHsVideoModulesWithoutAccess || isABTestWithoutAccess || hasSmartContentWithoutAccess || isMABExperimentWithoutAccess);
const getHasAdvancedContentWithoutAccessAndHasNotSeenModal = createSelector([getHasAdvancedContentWithoutAccess, getHasSeenScopesHaveChangedModal], (hasAdvancedContentWithoutAccess, hasSeenScopesHaveChangedModal) => hasAdvancedContentWithoutAccess && !hasSeenScopesHaveChangedModal);
export const getContentIsEligleForScopesHaveChangedModal = createSelector([getHasAdvancedContentWithoutAccessAndHasNotSeenModal, getContentHasPaymentsFieldWithoutAccess, getContentHasPersonalizationTokensWithoutAccess], (hasAdvancedContentWithoutAccessAndHasNotSeenModal, contentHasPaymentsFieldWithoutAccess, contentHasPersonalizationTokensWithoutAccess) => hasAdvancedContentWithoutAccessAndHasNotSeenModal || contentHasPaymentsFieldWithoutAccess || contentHasPersonalizationTokensWithoutAccess);
export const getShouldShowScopesHaveChangedModal = createSelector([getContentIsEligleForScopesHaveChangedModal, getHasScopesHaveChangedModalBeenDismissed], (isContentElligleForScopesHaveChangedModal, hasScopesHaveChangedModalBeenDismissed) => isContentElligleForScopesHaveChangedModal && !hasScopesHaveChangedModalBeenDismissed);
export const getIsShowingMatchingAppModal = createModalInfoSelector('showMatchingAppModal');
export const getMatchingAppOpenLocation = createModalInfoSelector('matchingAppOpenLocation');
export const getSelectedUpgradableModulePQL = createModalInfoSelector('selectedUpgradableModule');
export const getPatagoniaSettingsModalActiveNavItem = createModalInfoSelector('patagoniaSettingsModalActiveNavItem');
export const getRunTestModalOpen = createModalInfoSelector('runTestModalOpen');
export const getOpenTestType = createModalInfoSelector('openTestType');