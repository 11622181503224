import { useEffect, useMemo } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from 'collaboration-sidebar/utils/localStorage';
import { shouldHideOnboardingForSelenium } from '../utils/env';
export default function useOnboardingTour({
  featureIsVisible,
  tourId,
  localStorageKey
}) {
  const showTour = useMemo(() => {
    if (!featureIsVisible || shouldHideOnboardingForSelenium()) {
      return false;
    }

    const searchParams = new URLSearchParams(document.location.search);
    const onboardingTourId = searchParams.get('onboardingTourId');
    const isTourInQueryParam = onboardingTourId === tourId;
    const isTourComplete = !!getLocalStorageItem(localStorageKey);
    return isTourInQueryParam || isTourComplete === false;
  }, [featureIsVisible, localStorageKey, tourId]);
  useEffect(() => {
    // Mark the tour as complete if tour is showing
    if (showTour) {
      setLocalStorageItem(localStorageKey, 'true');
    }
  }, [showTour, localStorageKey]);
  return showTour;
}