import { useCallback, useEffect, useRef, useState } from 'react';
import { fetchContentAssistanceGeneratedContent } from '../api/contentAssistanceApi';
import { ERROR, FINISHED, NOT_STARTED, STARTED } from './constants';
import { useUsageTracker } from './useUsageTracker';
import { useHttpClient } from './useHttpClient';
// Used by internal "off the shelf" components
export const useFetchContentAssistanceContent = (onSuccess, onError) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(NOT_STARTED);
  const http = useHttpClient();
  const getGeneratedContent = useCallback(({
    commandId,
    parameters,
    resultCount
  }) => {
    setRequest(STARTED);
    fetchContentAssistanceGeneratedContent({
      http,
      commandId,
      parameters,
      resultCount
    }).then(response => {
      setResult(response);
      onSuccess(response);
      setRequest(FINISHED);
    }).catch(e => {
      setRequest(ERROR);
      setError(e);
      onError(e);
    });
  }, [http, onSuccess, onError]);
  const reset = useCallback(() => {
    setResult([]);
    setRequest(NOT_STARTED);
    setError(null);
  }, []);
  return {
    request,
    error,
    result,
    reset,
    fetch: getGeneratedContent
  };
};

const messageIsFromValidDomain = evt => {
  const {
    origin
  } = evt;
  return origin.includes('.hubspot.com') || origin.includes('.hubspotqa.com') || // CMS Domains
  origin.includes('.hubspotpreviewqa-na1.com') || origin.includes('.hubspotpreviewqa-eu1.com') || origin.includes('.hubspotpreview-na1.com') || origin.includes('.hubspotpreview-eu1.com');
}; // A hook for use in a parent app that lives on the app.hubspot.com domain, it listens for post messages from other domains (CMS Editors is the primary use case, as the preview iframes are on portal specific domains)


export const useDelegatedFetchGeneratedContent = (childFrameName, usageTracker) => {
  const trackUsage = useUsageTracker({
    usageTracker,
    usageDescription: 'embeded-generation'
  });
  const http = useHttpClient();
  useEffect(() => {
    // Listen for requests
    const listener = event => {
      if (messageIsFromValidDomain(event) && event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_REQUEST') {
        const {
          commandId,
          parameters,
          resultCount
        } = event.data;
        fetchContentAssistanceGeneratedContent({
          http,
          commandId,
          parameters,
          resultCount
        }).then(results => {
          window.frames[childFrameName].postMessage({
            type: 'CONTENT_ASSISTANCE_DELEGATED_RESPONSE',
            results
          }, '*');
        }).catch(error => {
          window.frames[childFrameName].postMessage({
            type: 'CONTENT_ASSISTANCE_DELEGATED_ERROR',
            error
          }, '*');
        });
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [http, childFrameName, trackUsage]);
};

const useSetupFetchGeneratedContent = () => {
  const resolveRef = useRef();
  const errorRef = useRef(); // Send requests to parent frame

  const requestPromptCompletion = ({
    commandId,
    parameters,
    resultCount = 1
  }) => {
    const completionPromise = new Promise((resolve, error) => {
      resolveRef.current = resolve;
      errorRef.current = error;
    }); // todo make this not be a wild card

    window.parent.postMessage({
      type: 'CONTENT_ASSISTANCE_DELEGATED_REQUEST',
      commandId,
      parameters,
      resultCount
    }, '*');
    return completionPromise;
  }; // Listen to responses


  useEffect(() => {
    const listener = event => {
      if (!messageIsFromValidDomain(event)) {
        return;
      }

      if (event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_RESPONSE') {
        if (resolveRef.current) {
          resolveRef.current(event.data.results);
        }
      }

      if (event.data.type === 'CONTENT_ASSISTANCE_DELEGATED_ERROR') {
        if (errorRef.current) {
          errorRef.current(event.data.error);
        }
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return requestPromptCompletion;
};

export const useProxiedFetchGeneratedContent = (onSuccess, onError) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState(NOT_STARTED);
  const fetchGeneratedContent = useSetupFetchGeneratedContent();
  const fetchCommand = useCallback(({
    commandId,
    parameters,
    resultCount
  }) => {
    setRequest(STARTED);
    fetchGeneratedContent({
      commandId,
      parameters,
      resultCount
    }).then(response => {
      setResult(response);
      onSuccess(response);
      setRequest(FINISHED);
    }).catch(e => {
      setRequest(ERROR);
      setError(e);
      onError(e);
    });
  }, [fetchGeneratedContent, onSuccess, onError]);
  const reset = useCallback(() => {
    setResult([]);
    setRequest(NOT_STARTED);
    setError(null);
  }, []);
  return {
    request,
    error,
    result,
    reset,
    fetch: fetchCommand
  };
};