'use es6';

import { Component } from 'react';
import PropTypes from 'prop-types';
import withEditorActionsAndEvents from 'ContentEditorUI/components/hoc/withEditorActionsAndEvents';
import { connect } from 'react-redux';
import { setIframeLoading as setIframeLoadingAction } from 'ContentEditorUI/redux/actions/appActions';
import { showPreviewLoadingOverlay, hidePreviewLoadingOverlay, showSidebarIframeSpinner } from 'ContentEditorUI/lib/layoutDom';
import logSeleniumEvent from 'ContentEditorUI/utils/logSeleniumEvent';
import { updateInpageMode as updateInpageModeAction } from 'ContentEditorUI/redux/actions/inpageActions';
import { setIframeHidden as setIframeHiddenAction } from 'ContentEditorUI/redux/actions/appActions';
import { getIframeCaresAboutNextWindowClick } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { getPreviewDomain, getPreviewIframeUrl, getSidebarIframeUrl } from 'ContentEditorUI/redux/selectors/iframesSelectors';
import { getCurrentTabId } from 'ContentEditorUI/redux/selectors/routingSelectors';

const mapStateToProps = state => {
  return {
    currentTabId: getCurrentTabId(state),
    previewDomain: getPreviewDomain(state),
    previewIframeUrl: getPreviewIframeUrl(state),
    sidebarIframeUrl: getSidebarIframeUrl(state),
    iframeCaresAboutNextWindowClick: getIframeCaresAboutNextWindowClick(state)
  };
};

const mapDispatchToProps = {
  setIframeLoading: setIframeLoadingAction,
  updateInpageMode: updateInpageModeAction,
  setIframeHidden: setIframeHiddenAction
};

class EditorIframesCoordinator extends Component {
  constructor(props) {
    super(props);

    this.onPreviewFrameLoaded = () => {
      const {
        showAndHideLoadingOverlay
      } = this.props;

      if (showAndHideLoadingOverlay) {
        hidePreviewLoadingOverlay();
      }
    };

    this.getReadyToReloadPreviewIframe = () => {
      const {
        setIframeLoading,
        bus,
        showAndHideLoadingOverlay,
        selectedItemId
      } = this.props;
      showSidebarIframeSpinner();
      bus.ready = false;
      setIframeLoading({
        scrollIfAnyBoundsOutOfView: !!selectedItemId
      });
      logSeleniumEvent('startIframeReload');

      if (showAndHideLoadingOverlay) {
        showPreviewLoadingOverlay();
      }

      bus.postMessage({
        action: 'previewNeedsReload'
      });
    };

    this.tellIframeSidebarClicked = () => {
      const {
        bus
      } = this.props;
      bus.postMessage({
        action: 'sidebarClicked'
      });
    };

    this.onWindowClick = () => {
      const {
        iframeCaresAboutNextWindowClick,
        bus
      } = this.props;

      if (iframeCaresAboutNextWindowClick) {
        bus.postMessage({
          action: 'sidebarClicked'
        });
      }
    };

    this.previewIframe = document.querySelector('iframe#contentEditor-preview');
    this.sidebarIframe = document.querySelector('iframe#contentEditor-sidebar');
    this.setupDomEvents();
    this.setupPreviewFrameIntersectionObserver(); // If the iframe src URLs have alredy been set, setup events and messaging as early as possible

    if (this.hasIframeStartedLoading()) {
      this.setupPreviewFrameDomEvents();
      this.setupMessagingToFrame();
    }
  }

  componentDidMount() {
    const {
      previewIframeUrl,
      sidebarIframeUrl
    } = this.props; // If a customer went straight to route that doesn't early load the iframes, the preview-key
    // API failed, or other early logic busted then load up the iframes again.

    if (!this.hasIframeStartedLoading() && previewIframeUrl) {
      this.startingTheIframesAgain({
        previewIframeUrl,
        sidebarIframeUrl
      });
    }
  }

  hasIframeStartedLoading() {
    return !!window.hsPreviewIframeStartTimestamp; // early request logic global
  }

  startingTheIframesAgain({
    previewIframeUrl,
    sidebarIframeUrl
  }) {
    window.hsPreviewIframeStartTimestamp = Date.now();
    this.previewIframe.src = previewIframeUrl;
    this.setupPreviewFrameDomEvents();
    this.setupPreviewFrameIntersectionObserver();
    this.setupMessagingToFrame();

    if (sidebarIframeUrl) {
      this.sidebarIframe.src = sidebarIframeUrl;
    }
  }

  setupDomEvents() {
    document.addEventListener('mousedown', this.onWindowClick);
  }

  setupPreviewFrameDomEvents() {
    // If preview frame load event already fired before react started up, call the onload handler now
    if (window.hsPreviewIframeLoaded) {
      this.onPreviewFrameLoaded();
    } // Also bind future load events for when refreshes are required


    this.previewIframe.addEventListener('load', this.onPreviewFrameLoaded);
  } // This method tells the CEUI event bus to set up interframe / post messaging with the preview frame


  setupMessagingToFrame() {
    const {
      bus,
      previewDomain
    } = this.props;
    bus.setupMessagingToFrame(this.previewIframe.contentWindow, previewDomain); // Listen for events where something in the app wants to refresh the iframe

    bus.on('preview:refresh', this.getReadyToReloadPreviewIframe); // Listen for events where a click occured in the add module sidebar iframe

    bus.on('post:tellIframeSidebarClicked', this.tellIframeSidebarClicked);
  }

  setupPreviewFrameIntersectionObserver() {
    const {
      setIframeHidden
    } = this.props;

    if (this.previewFrameObserver) {
      this.previewIframeObserver.observe(this.previewIframe);
      return;
    }

    this.previewIframeObserver = new IntersectionObserver(entries => {
      if (entries[0]) {
        setIframeHidden(!entries[0].isIntersecting);
      }
    });
    this.previewIframeObserver.observe(this.previewIframe);
  }

  render() {
    return null;
  }

}

EditorIframesCoordinator.propTypes = {
  bus: PropTypes.object.isRequired,
  previewDomain: PropTypes.string.isRequired,
  previewIframeUrl: PropTypes.string.isRequired,
  sidebarIframeUrl: PropTypes.string,
  iframeCaresAboutNextWindowClick: PropTypes.bool.isRequired,
  setIframeLoading: PropTypes.func.isRequired,
  showAndHideLoadingOverlay: PropTypes.bool,
  selectedItemId: PropTypes.string,
  setIframeHidden: PropTypes.func
};
EditorIframesCoordinator.defaultProps = {
  showAndHideLoadingOverlay: true
};
export default connect(mapStateToProps, mapDispatchToProps)(withEditorActionsAndEvents(EditorIframesCoordinator));