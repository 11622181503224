'use es6';

import { combineReducers } from 'redux'; // Reducers from CEUI   ---------------------------------------------------------------------
// App

import appStatusReducer from 'ContentEditorUI/redux/reducers/appStatusReducer';
import authReducer from 'ContentEditorUI/redux/reducers/authReducer';
import contentSchemaReducer from 'ContentEditorUI/redux/reducers/contentSchemaReducer';
import userInterfaceReducer from 'ContentEditorUI/redux/reducers/userInterfaceReducer';
import panesVisitStatusReducer from 'ContentEditorUI/redux/reducers/panesVisitStatusReducer';
import portalReducer from 'ContentEditorUI/redux/reducers/portalReducer';
import usersReducer from 'ContentEditorUI/js/redux/reducers/usersReducer';
import userAttributesReducer from 'ContentEditorUI/redux/reducers/userAttributesReducer';
import locallyCachedSettingsReducer from 'ContentEditorUI/redux/reducers/locallyCachedSettingsReducer';
import { sidebarStateReducer } from 'ContentEditorUI/redux/reducers/sidebarStateReducer';
import selectionReducer from 'ContentEditorUI/redux/reducers/selectionReducer';
import iframesReducer from 'ContentEditorUI/redux/reducers/iframesReducer';
import brandSettingsReducer from 'ContentEditorUI/redux/reducers/brandSettingsReducer';
import { categorizationReducer } from 'ContentEditorUI/redux/reducers/categorizationReducer';
import modalReducer, { modalImmerReducer } from 'ContentEditorUI/redux/reducers/modalReducer';
import sectionsReducer, { sectionsImmerReducer } from 'ContentEditorUI/redux/reducers/sectionsReducer';
import createCollaborationSidebarReducer from 'ContentEditorUI/redux/reducers/createCollaborationSidebarReducer';
import locationReducer from 'ContentEditorUI/redux/reducers/locationReducer'; // Modules

import justSavedModuleReducer from 'ContentEditorUI/redux/reducers/justSavedModuleReducer';
import editSidebarReducer from 'ContentEditorUI/redux/reducers/editSidebarReducer';
import moduleSchemaReducer from 'ContentEditorUI/redux/reducers/moduleSchemaReducer';
import moduleReducer from 'ContentEditorUI/redux/reducers/moduleReducer';
import createUndoableModuleReducer from 'ContentEditorUI/redux/reducers/undoableModuleReducer';
import moduleErrorReducer from 'ContentEditorUI/redux/reducers/moduleErrorReducer';
import deleteRequestReducer from 'ContentEditorUI/redux/reducers/deleteRequestReducer';
import cloneRequestReducer from 'ContentEditorUI/redux/reducers/cloneRequestReducer'; // Content

import resources from 'ContentData/reducers/Resources';
import editingInfoReducer from 'ContentEditorUI/redux/reducers/editingInfoReducer';
import saveDataReducer from 'ContentEditorUI/redux/reducers/saveDataReducer';
import formStatusReducer from 'ContentEditorUI/redux/reducers/formStatusReducer';
import pagesReducer from 'ContentEditorUI/redux/reducers/pagesReducer';
import abTestReducer from 'ContentEditorUI/redux/reducers/abTestReducer';
import accountVerificationStatusReducer from 'ContentEditorUI/redux/reducers/accountVerificationStatusReducer';
import contentDataReducer from 'ContentEditorUI/redux/reducers/contentReadOnlyDataReducer';
import layoutDataReducer from 'ContentEditorUI/redux/reducers/layoutDataReducer';
import languageReducer from 'ContentEditorUI/redux/reducers/languageReducer';
import mabExperimentReducer from 'page-editor-ui/redux/reducers/mabExperimentReducer';
import revisionsReducer from 'ContentEditorUI/redux/reducers/revisionsReducer';
import previewInfoReducer from 'ContentEditorUI/redux/reducers/previewInfoReducer';
import hardSavedContentModelReducer from 'ContentEditorUI/redux/reducers/contentModel/hardSavedContentModelReducer';
import styleBreakpointsReducer from 'ContentEditorUI/redux/reducers/styleBreakpointsReducer';
import contentAssetsReducer from 'ContentEditorUI/redux/reducers/contentAssetsReducer';
import onboardingTourStateReducer from 'ContentEditorUI/redux/reducers/onboardingTourStateReducer';
import optimizationsReducer from 'ContentEditorUI/redux/reducers/optimizationsReducer';
import inpageOptimizationsReducer from 'ContentEditorUI/redux/reducers/inpageOptimizationReducer';
import headerReducer from 'ContentEditorUI/redux/reducers/headerReducer'; // Smart View

import smartViewReducer from 'ContentEditorUI/redux/reducers/smartViewReducer'; // Inpage Editor

import inpageReducer from 'ContentEditorUI/redux/reducers/inpageReducer';
import inpageModals from 'tinymce-plugins/reducers/InpageModals';
import siteSettingsReducer from 'ContentEditorUI/redux/reducers/siteSettingsReducer';
import portalDomainsReducer from 'ContentEditorUI/redux/reducers/portalDomainsReducer';
import templateInfoReducer from 'ContentEditorUI/redux/reducers/templateInfoReducer';
import themeReducer from 'ContentEditorUI/redux/reducers/themeReducer';
import uploadedFeaturedImagesReducer from 'ContentEditorUI/redux/reducers/uploadedFeaturedImagesReducer';
import testFlagsReducer from 'ContentEditorUI/redux/reducers/testFlagsReducer'; // Instance editing

import instanceEditingReducer from 'ContentEditorUI/redux/reducers/instanceEditingReducer'; // Reducers from page-editor-ui ---------------------------------------------------------------------

import pageContentModelReducer from 'page-editor-ui/redux/reducers/pageContentModelReducer';
import pageRequestStatesReducer from 'page-editor-ui/redux/reducers/pageRequestStatesReducer';
import pageSettingsReducer from 'page-editor-ui/redux/reducers/pageSettingsReducer';
import pageLimitReducer from 'page-editor-ui/redux/reducers/pageLimitReducer';
import { extraInitialStateReducer } from 'page-editor-ui/redux/reducers/extraInitialStateReducer';
import testVariationTourStatusReducer from './testVariationTourStatusReducer';
import immutableToImmerReducer from 'ContentEditorUI/redux/reduxReducerDiffer';
import { runDiffsOnFiftyPercentOfAppLoads } from 'ContentEditorUI/redux/reducers/reduxReducerDifferUtils';
export default combineReducers({
  // Reducers from CEUI
  abTest: abTestReducer,
  accountVerificationStatus: accountVerificationStatusReducer,
  appStatus: appStatusReducer,
  auth: authReducer,
  brandSettings: brandSettingsReducer,
  categorizations: categorizationReducer,
  collaborationSidebar: createCollaborationSidebarReducer(),
  contentReadOnlyData: contentDataReducer,
  contentAssets: contentAssetsReducer,
  contentModel: pageContentModelReducer,
  contentSchema: contentSchemaReducer,
  editSidebar: editSidebarReducer,
  panesVisitStatus: panesVisitStatusReducer,
  portalDomains: portalDomainsReducer,
  portal: portalReducer,
  editingInfo: editingInfoReducer,
  saveData: saveDataReducer,
  formStatus: formStatusReducer,
  iframes: iframesReducer,
  inpageData: inpageReducer,
  inpageModals,
  instanceEditing: instanceEditingReducer,
  styleBreakpoints: styleBreakpointsReducer,
  justSavedModule: justSavedModuleReducer,
  locallyCachedSettings: locallyCachedSettingsReducer,
  moduleErrors: moduleErrorReducer,
  deleteRequest: deleteRequestReducer,
  cloneRequest: cloneRequestReducer,
  moduleSchemas: moduleSchemaReducer,
  modules: createUndoableModuleReducer(moduleReducer),
  pages: pagesReducer,
  siteSettings: siteSettingsReducer,
  requestStates: pageRequestStatesReducer,
  revisions: revisionsReducer,
  resources,
  selection: selectionReducer,
  sidebarState: sidebarStateReducer,
  smartView: smartViewReducer,
  templateInfo: templateInfoReducer,
  testFlags: testFlagsReducer,
  theme: themeReducer,
  uploadedFeaturedImages: uploadedFeaturedImagesReducer,
  users: usersReducer,
  layoutData: layoutDataReducer,
  languages: languageReducer,
  userInterface: userInterfaceReducer,
  userAttributes: userAttributesReducer,
  mabExperiment: mabExperimentReducer,
  previewInfo: previewInfoReducer,
  hardSavedContentModel: hardSavedContentModelReducer,
  onboardingTourState: onboardingTourStateReducer,
  modals: immutableToImmerReducer(modalReducer, modalImmerReducer, {
    reducerName: 'modal',
    reducerToReturn: 'immutable',
    prodConfig: {
      computeDiffOnProd: 'conditionally',
      condition: runDiffsOnFiftyPercentOfAppLoads
    }
  }),
  sections: immutableToImmerReducer(sectionsReducer, sectionsImmerReducer, {
    reducerName: 'sections',
    reducerToReturn: 'immutable',
    prodConfig: {
      computeDiffOnProd: 'conditionally',
      condition: runDiffsOnFiftyPercentOfAppLoads
    }
  }),
  optimizationState: optimizationsReducer,
  inpageOptimizationState: inpageOptimizationsReducer,
  header: headerReducer,
  __location: locationReducer,
  // Reducers from PageEditorUI
  contentTypeLimit: pageLimitReducer,
  pageSettings: pageSettingsReducer,
  extraInitialState: extraInitialStateReducer,
  testVariationTourStatus: testVariationTourStatusReducer
});