'use es6';

import { Map as ImmutableMap } from 'immutable';
import { OPEN_ADD_SECTION_PANEL, CLOSE_ADD_SECTION_PANEL, CUSTOM_LAYOUT_SECTION_ADDED, OPEN_CREATE_NEW_SECTION_PANEL, CLOSE_CREATE_NEW_SECTION_PANEL, SAVE_SECTION_REQUEST, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAILURE, REDIRECT_TO_DELETE_SECTIONS, FETCH_GENERATED_LAYOUT_HUBL_STARTED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS, UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO, OPEN_DELETE_SECTION_MODAL, CLOSE_DELETE_SECTION_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
import { produce } from 'immer';
const defaultState = {
  showAddSectionPanel: false,
  currentLayoutSectionToAddTo: null,
  currentCreateNewSectionInfo: null,
  showCreateNewSectionPanel: false,
  showDeleteSectionModal: false,
  sectionIdStagedForDeletion: null,
  saveNewSectionError: null,
  currentCopyFragmentAsHublInfo: null,
  addSectionMissingModuleSchemaErrors: 0
};
export const sectionsImmerReducer = (state = defaultState, action) => produce(state, draft => {
  switch (action.type) {
    case OPEN_ADD_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        const currentLayoutSectionToAddTo = {
          layoutSectionId,
          rowIdToInsertAround: rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        };
        draft.currentLayoutSectionToAddTo = currentLayoutSectionToAddTo;
        draft.showAddSectionPanel = true;
        return draft;
      }

    case CLOSE_ADD_SECTION_PANEL:
      {
        draft.showAddSectionPanel = false;
        draft.currentLayoutSectionToAddTo = null;
        return draft;
      }

    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        draft.showAddSectionPanel = false;
        draft.currentLayoutSectionToAddTo = null;
        draft.addSectionMissingModuleSchemaErrors = 0;
        return draft;
      }

    case UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        const currentLayoutSectionToAddTo = {
          layoutSectionId,
          rowIdToInsertAround: rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        };
        draft.currentLayoutSectionToAddTo = currentLayoutSectionToAddTo;
        return draft;
      }

    case OPEN_DELETE_SECTION_MODAL:
      {
        const {
          layoutSectionId
        } = action.payload;
        draft.showDeleteSectionModal = true;
        draft.sectionIdStagedForDeletion = layoutSectionId;
        return draft;
      }

    case CLOSE_DELETE_SECTION_MODAL:
      {
        draft.showDeleteSectionModal = false;
        draft.sectionIdStagedForDeletion = null;
        return draft;
      }

    case OPEN_CREATE_NEW_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        const currentCreateNewSectionInfo = {
          layoutSectionId,
          sectionName
        };
        draft.currentCreateNewSectionInfo = currentCreateNewSectionInfo;
        draft.showCreateNewSectionPanel = true;
        return draft;
      }

    case SAVE_SECTION_REQUEST:
      draft.saveNewSectionError = null;
      return draft;

    case SAVE_SECTION_SUCCESS:
    case CLOSE_CREATE_NEW_SECTION_PANEL:
      {
        draft.showCreateNewSectionPanel = false;
        draft.currentCreateNewSectionInfo = null;
        return draft;
      }

    case SAVE_SECTION_FAILURE:
      draft.saveNewSectionError = action.payload;
      return draft;

    case REDIRECT_TO_DELETE_SECTIONS:
      draft.showCreateNewSectionPanel = false;
      draft.currentCreateNewSectionInfo = null;
      draft.showAddSectionPanel = true;
      return draft;

    case FETCH_GENERATED_LAYOUT_HUBL_STARTED:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        const currentCopyFragmentAsHublInfo = {
          layoutSectionId,
          sectionName
        };
        draft.currentCopyFragmentAsHublInfo = currentCopyFragmentAsHublInfo;
        return draft;
      }

    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const missingModuleSchemaCount = action.payload.moduleSchemaErrorCount;
        draft.addSectionMissingModuleSchemaErrors = missingModuleSchemaCount;
        return draft;
      }

    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        draft.addSectionMissingModuleSchemaErrors = 0;
        return draft;
      }

    default:
      return draft;
  }
});

const sectionsReducer = (state = ImmutableMap(defaultState), action) => {
  switch (action.type) {
    case OPEN_ADD_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        return state.merge({
          currentLayoutSectionToAddTo: {
            layoutSectionId,
            rowIdToInsertAround: rowId,
            isEmptyLayoutSection,
            targetBeforeOrAfter
          },
          showAddSectionPanel: true
        });
      }

    case CLOSE_ADD_SECTION_PANEL:
      {
        return state.merge({
          showAddSectionPanel: false,
          currentLayoutSectionToAddTo: null
        });
      }

    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        return state.merge({
          showAddSectionPanel: false,
          currentLayoutSectionToAddTo: null,
          addSectionMissingModuleSchemaErrors: 0
        });
      }

    case UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        return state.merge({
          currentLayoutSectionToAddTo: {
            layoutSectionId,
            rowIdToInsertAround: rowId,
            isEmptyLayoutSection,
            targetBeforeOrAfter
          }
        });
      }

    case OPEN_DELETE_SECTION_MODAL:
      {
        const {
          layoutSectionId
        } = action.payload;
        return state.merge({
          showDeleteSectionModal: true,
          sectionIdStagedForDeletion: layoutSectionId
        });
      }

    case CLOSE_DELETE_SECTION_MODAL:
      {
        return state.merge({
          showDeleteSectionModal: false,
          sectionIdStagedForDeletion: null
        });
      }

    case OPEN_CREATE_NEW_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        return state.merge({
          showCreateNewSectionPanel: true,
          currentCreateNewSectionInfo: {
            layoutSectionId,
            sectionName
          }
        });
      }

    case SAVE_SECTION_REQUEST:
      return state.merge({
        saveNewSectionError: null
      });

    case SAVE_SECTION_SUCCESS:
    case CLOSE_CREATE_NEW_SECTION_PANEL:
      {
        return state.merge({
          showCreateNewSectionPanel: false,
          currentCreateNewSectionInfo: null
        });
      }

    case SAVE_SECTION_FAILURE:
      return state.merge({
        saveNewSectionError: action.payload
      });

    case REDIRECT_TO_DELETE_SECTIONS:
      return state.merge({
        showCreateNewSectionPanel: false,
        currentCreateNewSectionInfo: null,
        showAddSectionPanel: true
      });

    case FETCH_GENERATED_LAYOUT_HUBL_STARTED:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        return state.merge({
          currentCopyFragmentAsHublInfo: {
            layoutSectionId,
            sectionName
          }
        });
      }

    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const missingModuleSchemaCount = action.payload.moduleSchemaErrorCount;
        return state.merge({
          addSectionMissingModuleSchemaErrors: missingModuleSchemaCount
        });
      }

    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        return state.merge({
          addSectionMissingModuleSchemaErrors: 0
        });
      }

    default:
      return state;
  }
};

export default sectionsReducer;