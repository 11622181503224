import { combineReducers } from 'redux';
import { UNINITIALIZED, PENDING, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
import { PAYMENTS_FETCH_PAYMENT, PAYMENTS_FETCH_PAYMENTS, PAYMENTS_FETCH_PAYMENT_ELIGIBILITY } from 'ContentData/actions/ActionTypes';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import { PaymentsEligibilityStatus, PaymentsEligiblityUnderwritingStatus } from '../types/redux/resources/payments';

//endpoint returns links in descending order by created date, so no additional sorting eneded here
function orderedPayments(state = {}, action) {
  switch (action.type) {
    case PAYMENTS_FETCH_PAYMENTS:
      {
        const {
          response
        } = action;
        return response && response.results ? response.results : state;
      }

    default:
      return state;
  }
}

function payments(state = {}, action) {
  switch (action.type) {
    case PAYMENTS_FETCH_PAYMENT:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }

    case PAYMENTS_FETCH_PAYMENTS:
      {
        const {
          response
        } = action;
        return response && response.results ? response.results.reduce((hash, payment) => {
          hash[payment.id] = payment;
          return hash;
        }, Object.assign({}, state)) : state;
      }

    default:
      return state;
  }
}

function paymentsRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case PAYMENTS_FETCH_PAYMENTS:
      {
        const {
          response,
          error
        } = action;
        if (response) return SUCCEEDED;
        if (error) return FAILED;
        return PENDING;
      }

    default:
      return state;
  }
}

function paymentsEligibility(state = {
  chargesEnabled: false,
  status: PaymentsEligibilityStatus.INELIGIBLE
}, action) {
  switch (action.type) {
    case PAYMENTS_FETCH_PAYMENT_ELIGIBILITY:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          chargesEnabled,
          status,
          underwritingStatus
        } = response;
        return {
          chargesEnabled,
          status: PaymentsEligibilityStatus[status],
          underwritingStatus: underwritingStatus && PaymentsEligiblityUnderwritingStatus[underwritingStatus]
        };
      }

    default:
      return state;
  }
}

function paymentsEligibilityRequestStatus(state = UNINITIALIZED, action) {
  switch (action.type) {
    case PAYMENTS_FETCH_PAYMENT_ELIGIBILITY:
      {
        const {
          response,
          error
        } = action;
        if (response) return SUCCEEDED;
        if (error) return FAILED;
        return PENDING;
      }

    default:
      return state;
  }
}

export default combineReducers({
  orderedPayments,
  payments,
  requestStatus: createGenericRequestStatusReducer(PAYMENTS_FETCH_PAYMENT),
  paymentsRequestStatus,
  paymentsEligibility,
  paymentsEligibilityRequestStatus
});