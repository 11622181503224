const CONTENT_ASSISTANCE_URL = 'content-assistance/v1/assist';

const escapeMarkup = result => {
  const tempDiv = document.createElement('div');
  tempDiv.innerText = result;
  return tempDiv.innerHTML;
};

const trimNewLines = result => result.replace(/^\s+|\s+$/g, '');

const brToNewLine = result => {
  return result.replace(/<br\/?>/g, '\n');
};

const sanitize = result => {
  const trimmedResult = trimNewLines(result);
  const escapedResult = escapeMarkup(trimmedResult); // returning innerHtml has the effect of turning newlines into brs we only support plain text results at the moment

  const plainTextResult = brToNewLine(escapedResult);
  return plainTextResult;
};

const sanitizeResults = results => {
  return results.map(sanitize);
}; // see https://tools.hubteamqa.com/puma/framework-app-settings/contentAssistanceCommands/entries
// new commands should be added there


export const fetchContentAssistanceGeneratedContent = ({
  http,
  commandId,
  parameters,
  resultCount = 1
}) => {
  return http.post(CONTENT_ASSISTANCE_URL, {
    data: {
      commandId,
      parameters,
      resultCount
    },
    timeout: 30000
  }).then(sanitizeResults);
};