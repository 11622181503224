import { COMM_METHODS_MODAL_TYPES } from './constants';
import { HAS_ASSIGNABLE_OVERRIDE, HAS_UNASSIGNABLE_OVERRIDE, HAS_FREE_OVERRIDE, HAS_PAID_OVERRIDE } from 'ui-addon-upgrades/_core/utils/commMethodOverrides';
import { getOwnedProducts } from 'self-service-api/api/getProducts';
import { track } from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
import { MARKETING_STARTER, MARKETING_STARTER_EMAIL, GENERAL } from 'self-service-api/constants/UpgradeProducts';
import { sourceKeys } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import validateUpgradeData from 'ui-addon-upgrades/_core/common/data/upgradeData/validateUpgradeData';
import { validateUpgradeProduct } from 'ui-addon-upgrades/_core/common/data/upgradeData/validators/validateUpgradeProduct';
import { getUpgradeDataTrackingProperties, getRepName } from 'ui-addon-upgrades/ums/utils';
import { getSource } from 'ui-addon-upgrades/_core/common/data/upgradeData/transformers/getPropertySource';
const {
  CONTACT_SALES_BUTTON
} = sourceKeys;
export const correctUpgradeData = upgradeData => {
  const {
    app,
    screen,
    uniqueId,
    upgradeProduct
  } = upgradeData;
  validateUpgradeData(upgradeData);
  const isValidUpgradeProduct = validateUpgradeProduct(upgradeProduct);
  const correctedUpgradeProduct = isValidUpgradeProduct ? upgradeProduct === MARKETING_STARTER ? MARKETING_STARTER_EMAIL : upgradeProduct : GENERAL;
  return Object.assign({}, upgradeData, {
    upgradeProduct: correctedUpgradeProduct,
    source: getSource({
      app,
      screen,
      uniqueId
    }, CONTACT_SALES_BUTTON)
  });
};
export const overrideAssignable = isAssignable => {
  if (HAS_ASSIGNABLE_OVERRIDE) return true;else if (HAS_UNASSIGNABLE_OVERRIDE) return false;else return !!isAssignable;
};
export const getIsFreePortal = () => {
  if (HAS_FREE_OVERRIDE) {
    return Promise.resolve(true);
  }

  if (HAS_PAID_OVERRIDE) {
    return Promise.resolve(false);
  }

  return getOwnedProducts().then(productsOwned => {
    if (!productsOwned || !Array.isArray(productsOwned)) {
      return true;
    } else {
      return productsOwned.length === 0;
    }
  }).catch(() => {
    return true;
  });
};
export const sequence = (...handlers) => {
  return (...args) => {
    handlers.forEach(handler => handler && handler(...args));
  };
};
export const trackContactSalesButtonView = props => {
  const {
    additionalUnitQuantity,
    isAssignable,
    isUpgradePoint,
    level,
    subscreen,
    termId,
    upgradeData,
    usageLimitBannerData
  } = props;
  track('contactSalesButtonInteraction', Object.assign({
    action: 'viewed'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    termId,
    additionalUnitQuantity,
    subscreen
  }));

  if (isUpgradePoint) {
    track('interaction', Object.assign({
      action: 'viewed talk to sales',
      isAssignable,
      isPrimaryCta: level === 1,
      isSecondaryCta: level === 2
    }, usageLimitBannerData, {}, getUpgradeDataTrackingProperties(upgradeData, isUpgradePoint)));
  }
};

const checkIsAssignable = ({
  isAssignable,
  isRetailPortal,
  repInfo
}) => {
  return !!(isAssignable && !isRetailPortal && repInfo && repInfo.link);
};

export const determineCommModal = ({
  repInfo,
  isRetailPortal,
  isAssignable
}) => {
  const isAssignableClick = checkIsAssignable({
    isAssignable,
    isRetailPortal,
    repInfo
  });

  if (isAssignableClick) {
    return COMM_METHODS_MODAL_TYPES.ASSIGNABLE;
  } else {
    return COMM_METHODS_MODAL_TYPES.UNASSIGNABLE;
  }
};
export const trackContactSalesButtonClick = (props, isRetailPortal, repInfo) => {
  const {
    additionalUnitQuantity,
    isAssignable,
    isUpgradePoint,
    level,
    subscreen,
    termId,
    upgradeData,
    usageLimitBannerData
  } = props;
  const isAssignableClick = checkIsAssignable({
    isAssignable,
    isRetailPortal,
    repInfo
  });
  track('contactSalesButtonInteraction', Object.assign({
    action: isAssignableClick ? 'clicked' : 'clicked talk to ISC'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    termId,
    additionalUnitQuantity,
    subscreen,
    repName: getRepName(repInfo)
  }));

  if (isUpgradePoint) {
    track('interaction', Object.assign({
      action: 'clicked upgrade point',
      isAssignable
    }, usageLimitBannerData, {}, getUpgradeDataTrackingProperties(upgradeData, isUpgradePoint)));
    track('interaction', Object.assign({
      action: 'clicked talk to sales',
      isAssignable,
      isPrimaryCta: level === 1,
      isSecondaryCta: level === 2
    }, usageLimitBannerData, {}, getUpgradeDataTrackingProperties(upgradeData, isUpgradePoint)));
  }
};
export const trackCommModalClose = (upgradeData, repInfo) => {
  track('communicationMethodsInteraction', Object.assign({
    action: 'closed'
  }, getUpgradeDataTrackingProperties(upgradeData), {
    repName: getRepName(repInfo)
  }));
};