'use es6';

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getOptimizationState = basicSelector(state => state.optimizationState || new ImmutableMap());
export const getHighlightedField = createSelector(getOptimizationState, optimizationState => optimizationState.get('highlightedField'));
export const getSettingOptimizationType = createSelector(getOptimizationState, optimizationState => optimizationState.get('settingOptimizationType'));
export const getModuleOptimizationType = createSelector(getOptimizationState, optimizationState => optimizationState.get('moduleOptimizationType'));
export const getImageModulesForAltText = createSelector(getOptimizationState, optimizationState => optimizationState.get('imageModulesForAltText'));
export const getXrayPageTitle = createSelector(getOptimizationState, optimizationState => optimizationState.get('pageTitle'));
export const getXrayMetaDesc = createSelector(getOptimizationState, optimizationState => optimizationState.get('metaDesc'));
export const getXrayTextAreas = createSelector(getOptimizationState, optimizationState => optimizationState.get('textAreas'));
export const getXrayConfigs = createSelector(getOptimizationState, optimizationState => optimizationState.get('configs'));
export const getGathererArtifacts = createSelector(getOptimizationState, optimizationState => optimizationState.get('gathererArtifacts'));
export const getXraySidebarSections = createSelector(getXrayConfigs, configs => configs.map(config => config.sectionType));
const getInpageOptimizationState = basicSelector(state => state.inpageOptimizationState || {});
export const getGathererKeys = createSelector(getInpageOptimizationState, inpageOptimizationState => inpageOptimizationState.gathererKeys || []);