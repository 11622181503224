import { AUD, JPY, GBP, SGD, EUR, COP } from './CurrencyCodes'; // A mapping of countries to official currencies, to predict best default activeCurrencyCode for a user.

export default {
  'isle of man': GBP,
  'new zealand': AUD,
  'reunion island': EUR,
  'san marino': EUR,
  'slovak republic': EUR,
  'united kingdom': GBP,
  andorra: EUR,
  australia: AUD,
  austria: EUR,
  bulgaria: EUR,
  belgium: EUR,
  // canada: CAD, TODO: uncomment once CAD launches in prod
  colombia: COP,
  czechia: EUR,
  cyprus: EUR,
  denmark: EUR,
  estonia: EUR,
  finland: EUR,
  france: EUR,
  germany: EUR,
  gibraltar: GBP,
  greece: EUR,
  hungary: EUR,
  ireland: EUR,
  italy: EUR,
  japan: JPY,
  jersey: GBP,
  latvia: EUR,
  lithuania: EUR,
  luxembourg: EUR,
  malta: EUR,
  monaco: EUR,
  netherlands: EUR,
  norway: EUR,
  poland: EUR,
  portugal: EUR,
  romania: EUR,
  singapore: SGD,
  slovakia: EUR,
  slovenia: EUR,
  spain: EUR,
  sweden: EUR,
  switzerland: EUR
};