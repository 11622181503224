import { createSelector } from 'reselect'; // @ts-expect-error file is untyped JS

import { getActiveDomain } from 'ContentEditorUI/redux/selectors/portalDomainsSelectors';
import { removeDomainFromTitle, removeTitleFromMetaDescription, addMetaDescription, addPageTitle, shortenMetaDescription, shortenPageTitle, openImageModule // @ts-expect-error file is untyped JS
} from 'ContentEditorUI/redux/actions/recommenderActions'; // @ts-expect-error file is untyped JS

import { getGathererArtifacts } from 'ContentEditorUI/redux/selectors/optimizationSelectors';
export const getDefaultXrayEditorApi = createSelector([getActiveDomain, getGathererArtifacts], (activeDomain, gathererArtifacts) => {
  return {
    getGathererArtifact: key => gathererArtifacts.get(key),
    activeDomainForXray: activeDomain.toJS().domain,
    addMetaDescription,
    addPageTitle,
    removeDomainFromTitle,
    removeTitleFromMetaDescription,
    openImageModule,
    shortenMetaDescription,
    shortenPageTitle
  };
});