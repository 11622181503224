// Common
export const MSG_NAVIGATE_TO_URL = 'MSG_NAVIGATE_TO_URL';
export const MSG_TYPE_UPDATE_COLLABORATION_SIDEBAR_STATE = 'MSG_TYPE_UPDATE_COLLABORATION_SIDEBAR_STATE';
export const MSG_WINDOW_CLICKED = 'MSG_WINDOW_CLICKED';
export const MSG_SHOW_ALERT = 'MSG_SHOW_ALERT'; // Comment

export const MSG_TYPE_CONFIRM_DELETE_COMMENT = 'MSG_TYPE_CONFIRM_DELETE_COMMENT';
export const MSG_TYPE_DELETE_COMMENT = 'MSG_TYPE_DELETE_COMMENT';
export const MSG_TYPE_DELETE_COMMENT_IN_CACHE = 'MSG_TYPE_DELETE_COMMENT_IN_CACHE';
export const MSG_TYPE_DELETE_COMMENT_SUCCESS = 'MSG_TYPE_DELETE_COMMENT_SUCCESS';
export const MSG_TYPE_DELETE_COMMENT_FAILURE = 'MSG_TYPE_DELETE_COMMENT_FAILURE';
export const MSG_TYPE_CREATE_COMMENT_FAILURE = 'MSG_TYPE_CREATE_COMMENT_FAILURE';
export const MSG_TYPE_CREATE_COMMENT_IN_CACHE = 'MSG_TYPE_CREATE_COMMENT_IN_CACHE';
export const MSG_TYPE_UPDATE_COMMENT_IN_CACHE = 'MSG_TYPE_UPDATE_COMMENT_IN_CACHE';
export const MSG_TYPE_UPDATE_COMMENT_FAILURE = 'MSG_TYPE_UPDATE_COMMENT_FAILURE';
export const MSG_TYPE_FETCH_COMMENTS_FAILURE = 'MSG_TYPE_FETCH_COMMENTS_FAILURE';
export const MSG_TYPE_FETCH_OWNERS_FAILURE = 'MSG_TYPE_FETCH_OWNERS_FAILURE';
export const MSG_TYPE_FETCH_TEAMS_FAILURE = 'MSG_TYPE_FETCH_TEAMS_FAILURE';
export const MSG_TYPE_FETCH_CALENDAR_TYPES_FAILURE = 'MSG_TYPE_FETCH_CALENDAR_TYPES_FAILURE';
export const MSG_TYPE_FETCH_CAMPAIGNS_FAILURE = 'MSG_TYPE_FETCH_CAMPAIGNS_FAILURE';
export const MSG_TYPE_FETCH_CALENDAR_EVENTS_FAILURE = 'MSG_TYPE_FETCH_CALENDAR_EVENTS_FAILURE';
export const MSG_TYPE_OPENED_MINI_COMMENTER = 'MSG_TYPE_OPENED_MINI_COMMENTER';
export const MSG_TYPE_RESIZE_MINI_COMMENTER = 'MSG_TYPE_RESIZE_MINI_COMMENTER';
export const MSG_TYPE_RESIZED_MINI_COMMENTER = 'MSG_TYPE_RESIZED_MINI_COMMENTER';
export const MSG_TYPE_CLOSE_MINI_COMMENTER = 'MSG_TYPE_CLOSE_MINI_COMMENTER';
export const MSG_TYPE_MINI_COMMENTER_HAS_TEXT = 'MSG_TYPE_MINI_COMMENTER_HAS_TEXT';
export const MSG_TYPE_MINI_COMMENTER_HAS_NO_TEXT = 'MSG_TYPE_MINI_COMMENTER_HAS_NO_TEXT';
export const MSG_TYPE_EMBEDDED_COMMENT = 'MSG_TYPE_EMBEDDED_COMMENT';
export const MSG_TYPE_REMOVE_EMBEDDED_COMMENT = 'MSG_TYPE_REMOVE_EMBEDDED_COMMENT';
export const MSG_TYPE_START_COMMENT_MODE = 'MSG_TYPE_START_COMMENT_MODE';
export const MSG_TYPE_TOGGLE_EMBEDDED_COMMENTS = 'MSG_TYPE_TOGGLE_EMBEDDED_COMMENTS';
export const MSG_TYPE_COMMENTS_UPDATED = 'MSG_TYPE_COMMENTS_UPDATED';
export const MSG_TYPE_COMMENTS_BROADCAST = 'MSG_TYPE_COMMENTS_BROADCAST';
export const MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION = 'MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION';
export const MSG_TYPE_COMMENTS_READ = 'MSG_TYPE_COMMENTS_READ';
export const MSG_TYPE_RESOLVE_COMMENT_SUCCESS = 'MSG_TYPE_RESOLVE_COMMENT_SUCCESS';
export const MSG_TYPE_UNRESOLVE_COMMENT_SUCCESS = 'MSG_TYPE_UNRESOLVE_COMMENT_SUCCESS';
export const MSG_TYPE_UNDO_RESOLVE_COMMENT = 'MSG_TYPE_UNDO_RESOLVE_COMMENT'; // Task create consts

export const MSG_TYPE_CREATE_TASK_SUCCESS = 'MSG_TYPE_CREATE_TASK_SUCCESS';
export const MSG_TYPE_CREATE_TASK_FAILURE = 'MSG_TYPE_CREATE_TASK_FAILURE'; // Task update consts

export const MSG_TYPE_UPDATE_TASK_SUCCESS = 'MSG_TYPE_UPDATE_TASK_SUCCESS';
export const MSG_TYPE_UPDATE_TASK_FAILURE = 'MSG_TYPE_UPDATE_TASK_FAILURE'; // Task delete consts

export const MSG_TYPE_DELETE_TASK = 'MSG_TYPE_DELETE_TASK';
export const MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL = 'MSG_TYPE_REQUEST_OPEN_DELETE_TASK_MODAL';
export const MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL = 'MSG_TYPE_REQUEST_CLOSE_DELETE_TASK_MODAL';
export const MSG_TYPE_CONFIRM_DELETE_TASK = 'MSG_TYPE_CONFIRM_DELETE_TASK';
export const MSG_TYPE_DELETE_TASK_SUCCESS = 'MSG_TYPE_DELETE_TASK_SUCCESS';
export const MSG_TYPE_DELETE_TASK_FAILURE = 'MSG_TYPE_DELETE_TASK_FAILURE'; //Calendar

export const MSG_TYPE_UPDATE_EVENT_FAILURE = 'MSG_TYPE_UPDATE_EVENT_FAILURE';
export const MSG_TYPE_UPDATE_EVENT_SUCCESS = 'MSG_TYPE_UPDATE_EVENT_SUCCESS';
export const MSG_TYPE_DELETE_EVENT_FAILURE = 'MSG_TYPE_DELETE_EVENT_FAILURE';
export const MSG_TYPE_DELETE_EVENT_SUCCESS = 'MSG_TYPE_DELETE_EVENT_SUCCESS';
export const MSG_TYPE_SET_CAMPAIGN_COLOR_FAILURE = 'MSG_TYPE_SET_CAMPAIGN_COLOR_FAILURE';
export const MSG_TYPE_SET_CAMPAIGN_COLOR_SUCCESS = 'MSG_TYPE_SET_CAMPAIGN_COLOR_SUCCESS'; // Campaign create

export const MSG_TYPE_OPEN_CAMPAIGN_CREATE_PANEL = 'MSG_TYPE_OPEN_CAMPAIGN_CREATE_PANEL';
export const MSG_TYPE_CONFIRM_CAMPAIGN_CREATE = 'MSG_TYPE_CONFIRM_CAMPAIGN_CREATE';
export const MSG_TYPE_CREATE_CAMPAIGN_SUCCESS = 'MSG_TYPE_CREATE_CAMPAIGN_SUCCESS';
export const MSG_TYPE_CREATE_CAMPAIGN_FAILURE = 'MSG_TYPE_CREATE_CAMPAIGN_FAILURE'; // Campaign association

export const MSG_TYPE_CAMPAIGN_ASSOCIATION_SUCCESS = 'MSG_TYPE_CAMPAIGN_ASSOCIATION_SUCCESS';
export const MSG_TYPE_CAMPAIGN_ASSOCIATION_FAILURE = 'MSG_TYPE_CAMPAIGN_ASSOCIATION_FAILURE';
export const MSG_TYPE_FETCH_CAMPAIGN_ASSETS_FAILURE = 'MSG_TYPE_FETCH_CAMPAIGN_ASSETS_FAILURE';
export const MSG_TYPE_FETCH_ASSOCIATED_CAMPAIGN_FAILURE = 'MSG_TYPE_FETCH_ASSOCIATED_CAMPAIGN_FAILURE';
export const MSG_TYPE_FETCH_CAMPAIGN_DATA_FAILURE = 'MSG_TYPE_FETCH_CAMPAIGN_DATA_FAILURE';
export const MSG_TYPE_FETCH_CAMPAIGNS_PAGINATED_FAILURE = 'MSG_TYPE_FETCH_CAMPAIGNS_PAGINATED_FAILURE';
export const MSG_TYPE_FETCH_CAMPAIGN_TASKS_TOTAL_FAILURE = 'MSG_TYPE_FETCH_CAMPAIGN_TASKS_TOTAL_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_AD_CAMPAIGN_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_AD_CAMPAIGN_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_BLOG_POST_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_BLOG_POST_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_BROADCAST_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_BROADCAST_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_CTA_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_CTA_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_EMAIL_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_EMAIL_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_LANDING_PAGE_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_LANDING_PAGE_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_SITE_PAGE_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_SITE_PAGE_FAILURE';
export const MSG_TYPE_FETCH_ASSET_DATA_WORKFLOW_FAILURE = 'MSG_TYPE_FETCH_ASSET_DATA_WORKFLOW_FAILURE';