'use es6';

import Immutable from 'immutable';
import { omit } from 'underscore';
import { importTreeFromLayoutDataApi } from 'layout-data-lib/LayoutDataTree/serialize';
import { setAlignmentOnAllColumnsInRow } from 'layout-data-lib/alignment/helpers';
import { appendRowToCell, insertColumnAfter, insertColumnBefore, appendColumnToEmptyRow, insertRowAfter, insertRowBefore, prependRowToCell } from 'layout-data-lib/CellAndRowsTree/insertHelpers';
import { BEFORE } from 'layout-dnd-utils/js/constants';
import { modifyColumnWidths, prepareNewModuleDataHelper } from 'ContentEditorUI/utils/layoutSectionTreeUtils';
import CustomCellTweakingAutoDeleteLogicPerTestFlag from 'ContentEditorUI/data/tree/CustomCellTweakingAutoDeleteLogicPerTestFlag';
import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { BODY_ID } from 'ContentEditorUI/lib/widgetEdit/constants';
import { addModuleToFlexColumnHelper, copyLayoutSectionFromSchemaToContentIfNeeded, copyLayoutSectionFromSchemaToContent, findTreeWithCellName, getKeyForNonLayoutSection, getLayoutSectionTreeToModify, incrementModuleEditVersion, mergeModuleBodyHelper, mergeModuleDataHelper, mergeLayoutFragmentDataHelper, normalizeFlexColumnData, removeCellFromLayoutSection, removeModule, removeRowFromLayoutSection, updateLayoutSectionTreeAndWidgets, updateMetaDataForModule } from 'ContentEditorUI/utils/moduleReducerUtils';
import { isEmailBodyName } from 'ContentEditorUI/data/moduleUtils';
import { hasTestFlag } from 'ContentEditorUI/lib/testFlags';
import { DND_DO_NOT_WRAP_SINGLE_MODULE_IN_SECTION_FLAG } from 'ContentEditorUI/redux/selectors/testFlagSelectors';
import themePageOverridesReducer from 'ContentEditorUI/redux/reducers/themePageOverridesReducer';
import { captureMessage } from 'ContentEditorUI/lib/exceptions';
import { Map as ImmutableMap } from 'immutable';
import { DND_AREA_ID, parseFlexAreaDataForLayoutTree } from 'ContentEditorUI/utils/email/contentTreeUtils';
export const moduleReducerInitialState = Immutable.fromJS({
  uneditableWidgets: {},
  schemaLayoutSectionTrees: {},
  // Suffix-ing with `tree` just to break previous references for now
  layoutSectionTrees: {},
  // Suffix-ing with `tree` just to break previous references for now
  layoutSectionWidgets: {},
  flexAreaTree: {},
  flexAreaSections: [],
  flexAreaStyles: {},
  flexAreaStyleSettings: {},
  userModuleDefaults: {},
  schemaWidgetContainers: {},
  widgetContainers: {},
  schemaWidgets: {},
  fakeModules: {},
  fakeModuleCustomUpdates: {},
  widgets: {},
  contentCss: {},
  moduleMetaData: {},
  clonedModulesPendingDomOperations: new Immutable.Set(),
  themePageOverrides: null,
  // Temporary hack to store this gate state in the reducer once, so we don't have to convert all of the actions
  // that impact this reducer into thunks that pass along the gate every time (there are lots of them)
  isUngatedForMergeInDefaultValues: false
});

const isNil = val => val === null || val === undefined;

const moduleReducer = (state = moduleReducerInitialState, action = {}) => {
  const {
    type,
    payload
  } = action; // Check if any of the fake modules should update from this action

  if (state.hasIn(['fakeModuleCustomUpdates', type])) {
    const {
      moduleName,
      updatePath
    } = state.getIn(['fakeModuleCustomUpdates', type]).toJS();
    const {
      metaData,
      isFakeUndoRedoProxiedUpdate
    } = action; // A fake undo redo proxied update is used by fakeUndoRedoUpdatesMiddleware. It should be ignored by this undoable module reducer
    // (but listened to by other non undoable reducers)

    if (!isFakeUndoRedoProxiedUpdate) {
      state = updateMetaDataForModule(state, moduleName, metaData);
      state = incrementModuleEditVersion({
        state,
        id: moduleName
      });

      if (metaData.updatedRichTextModuleName) {
        state = incrementModuleEditVersion({
          state,
          id: metaData.updatedRichTextModuleName
        });
      }

      const fakeModuleUpdatePath = ['fakeModules', moduleName, 'body', ...updatePath];
      const newValue = updatePath.reduce((current, key) => !isNil(current[key]) ? current[key] : payload, payload);

      if (state.getIn(fakeModuleUpdatePath) !== newValue) {
        state = state.setIn(fakeModuleUpdatePath, newValue);
      }
    }
  } // Test Flag for https://git.hubteam.com/HubSpot/ContentEditorUI/issues/6194 (that triggers a few different behaviors)


  const doNotWrapSingleModuleInSection = hasTestFlag(DND_DO_NOT_WRAP_SINGLE_MODULE_IN_SECTION_FLAG);
  const shouldRemoveUnnecessaryWrappersForSingleColumnSection = doNotWrapSingleModuleInSection;
  state = state.set('themePageOverrides', themePageOverridesReducer(state.get('themePageOverrides'), action));

  switch (type) {
    case actionTypes.FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const contentSchema = action.response;
        const {
          schema,
          content
        } = contentSchema;
        let schemaLayoutSectionTrees = new Immutable.Map();
        let layoutSectionTrees = new Immutable.Map();
        let uneditableWidgets = new Immutable.Map();
        let allWidgetNamesInFlexColumns = new Immutable.Set();
        let flexAreaTree = new Immutable.Map();
        const {
          isUngatedForMergeInDefaultValues,
          isCustomEmailUngatedForFlexAreasAndNewSidebar
        } = action;
        const flexAreaIsNotEmpty = content.flexAreas && Object.keys(content.flexAreas).length > 0;

        if (isCustomEmailUngatedForFlexAreasAndNewSidebar && flexAreaIsNotEmpty) {
          const flexAreaData = parseFlexAreaDataForLayoutTree(content.flexAreas, // TODO: circle back on this, there's some inconsistency between schema.widgets and content.widgets (schema widgets has the modules actually present in the email)
          schema.widgets);
          flexAreaTree = flexAreaTree.set(DND_AREA_ID, importTreeFromLayoutDataApi(flexAreaData, {
            shouldPreventEmptyRows: false,
            CellClass: CustomCellTweakingAutoDeleteLogicPerTestFlag
          }));
        }

        if (schema.widget_containers) {
          Object.keys(schema.widget_containers).forEach(containerName => {
            if (schema.widget_containers[containerName].widgets) {
              schema.widget_containers[containerName].widgets.forEach(widget => {
                allWidgetNamesInFlexColumns = allWidgetNamesInFlexColumns.add(widget.name);
              });
            }
          });
        }

        if (schema.layout_sections) {
          Object.keys(schema.layout_sections).forEach(lsId => {
            schemaLayoutSectionTrees = schemaLayoutSectionTrees.set(lsId, importTreeFromLayoutDataApi(schema.layout_sections[lsId], {
              shouldPreventEmptyRows: false,
              CellClass: CustomCellTweakingAutoDeleteLogicPerTestFlag
            }));
          });
        }

        if (content.layoutSections) {
          Object.keys(content.layoutSections).forEach(lsId => {
            layoutSectionTrees = layoutSectionTrees.set(lsId, importTreeFromLayoutDataApi(content.layoutSections[lsId], {
              shouldPreventEmptyRows: false,
              CellClass: CustomCellTweakingAutoDeleteLogicPerTestFlag
            }));
          });
        } // Collect all non-overrideable modules (that are not in flex columns) to store those on uneditableWidgets


        if (schema.all_widgets) {
          schema.all_widgets.forEach(widget => {
            if ((widget.global_partial || !widget.overrideable) && // TODO Branden can eventually remove this
            !allWidgetNamesInFlexColumns.has(widget.name) && !widget.layout_section_name) {
              uneditableWidgets = uneditableWidgets.set(widget.name, Immutable.fromJS(widget));
            }
          });
        } // Omit order from values inside layout_section_widgets


        const orderlessLayoutSectionWidgets = Immutable.fromJS(schema.layout_section_widgets || {}).map(moduleList => moduleList.map(m => m.filterNot((v, k) => k === 'order'))); // Filter out any `deleted_at` content widgets outside of containers (except for email bodies
        // to match https://git.hubteam.com/HubSpot/content4j/blob/2452d91c0c59af09e11112894796800c2c59a3e9/ContentModel/src/main/java/com/hubspot/content/model/Content.java#L439-L442 )

        const filteredContentWidgets = omit(content.widgets, widget => Boolean(widget.deleted_at) && !isEmailBodyName(widget.name));
        const widgetContainers = normalizeFlexColumnData(content.widgetContainers, schema.widget_containers);
        return state.merge({
          uneditableWidgets,
          schemaLayoutSectionTrees,
          layoutSectionTrees,
          flexAreaTree,
          // NOTE, trying to removing this but still need for now
          layoutSectionWidgets: orderlessLayoutSectionWidgets,
          schemaWidgetContainers: schema.widget_containers,
          widgetContainers,
          schemaWidgets: omit(schema.widgets, ['preview_text']),
          // instead lives in previewTextReducer
          widgets: omit(filteredContentWidgets, ['preview_text']),
          contentCss: content.css || {},
          // Temporary until fully rolled out
          isUngatedForMergeInDefaultValues
        });
      }

    case actionTypes.FETCH_TEMPLATE_SCHEMA_SUCCEEDED:
      {
        const templateSchema = action.response;
        const {
          schema
        } = templateSchema;
        const schemaLayoutSectionTrees = new Immutable.Map();
        const layoutSectionTrees = new Immutable.Map();
        const uneditableWidgets = new Immutable.Map();
        const {
          isUngatedForMergeInDefaultValues
        } = action;
        const orderlessLayoutSectionWidgets = Immutable.fromJS({}); // Filter out any `deleted_at` content widgets outside of containers (except for email bodies
        // to match https://git.hubteam.com/HubSpot/content4j/blob/2452d91c0c59af09e11112894796800c2c59a3e9/ContentModel/src/main/java/com/hubspot/content/model/Content.java#L439-L442 )

        const filteredContentWidgets = omit(schema.all_widgets, widget => Boolean(widget.deleted_at) && !isEmailBodyName(widget.name));
        const widgetContainers = normalizeFlexColumnData([]);
        return state.merge({
          uneditableWidgets,
          schemaLayoutSectionTrees,
          layoutSectionTrees,
          // NOTE, trying to removing this but still need for now
          layoutSectionWidgets: orderlessLayoutSectionWidgets,
          schemaWidgetContainers: schema.widget_containers || [],
          widgetContainers,
          schemaWidgets: omit(schema.all_widgets, ['preview_text']),
          // instead lives in previewTextReducer
          widgets: omit(filteredContentWidgets, ['preview_text']),
          contentCss: {},
          // Temporary until fully rolled out
          isUngatedForMergeInDefaultValues
        });
      }

    case actionTypes.MOVED_MODULE:
      {
        const {
          movingModuleId,
          newIndex,
          newColumnId
        } = payload;
        const module = state.getIn(getKeyForNonLayoutSection(state, movingModuleId));
        state = removeModule(state, movingModuleId);
        return addModuleToFlexColumnHelper(state, newColumnId, newIndex, module);
      }

    case actionTypes.ADDED_MODULE:
      {
        const {
          module,
          containerId,
          index
        } = payload;
        return addModuleToFlexColumnHelper(state, containerId, index, Immutable.fromJS(module));
      }

    case actionTypes.DELETED_MODULE:
      {
        const {
          id
        } = payload;
        return removeModule(state, id);
      }

    case actionTypes.LAYOUT_SECTION_MODULE_DELETED:
      {
        const {
          layoutSectionId,
          moduleId
        } = payload;
        return removeCellFromLayoutSection(state, layoutSectionId, moduleId, {
          shouldRemoveUnnecessaryWrappersForSingleColumnSection
        });
      }

    case actionTypes.LAYOUT_SECTION_COLUMN_DELETED:
      {
        const {
          layoutSectionId,
          columnId
        } = payload;
        return removeCellFromLayoutSection(state, layoutSectionId, columnId, {
          shouldRemoveUnnecessaryWrappersForSingleColumnSection
        });
      }

    case actionTypes.LAYOUT_SECTION_ROW_DELETED:
      {
        const {
          layoutSectionId,
          rowId
        } = payload;
        return removeRowFromLayoutSection(state, layoutSectionId, rowId);
      }

    case actionTypes.LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW:
      {
        const {
          rowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = appendColumnToEmptyRow(tree, rowId, {
          existingColumnId,
          newModuleSchemaJson,
          originTree
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_COLUMN_INSERTED_AFTER:
      {
        const {
          columnId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = insertColumnAfter(tree, columnId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_COLUMN_INSERTED_BEFORE:
      {
        const {
          columnId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = insertColumnBefore(tree, columnId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_ROW_INSERTED_AFTER:
      {
        const {
          rowId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          shouldWrapSingleModuleInSection = !doNotWrapSingleModuleInSection,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = insertRowAfter(tree, rowId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree,
          shouldWrapSingleModuleInSection
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_ROW_INSERTED_BEFORE:
      {
        const {
          rowId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          shouldWrapSingleModuleInSection = !doNotWrapSingleModuleInSection,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = insertRowBefore(tree, rowId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree,
          shouldWrapSingleModuleInSection
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_ROW_CLONED_BELOW:
      {
        const {
          rowId,
          layoutSectionId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const {
          tree: newTree,
          modifiedColumns,
          mapOfClonedToOldNodeName
        } = tree.cloneNewRowBelow(rowId); // Bail if this tree action was a no-op

        if (tree === newTree) {
          return state;
        } // Pass along the cloned and renamed modules for middlewares to update the DOM


        payload.mapOfClonedToOldNodeName = mapOfClonedToOldNodeName;
        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          modifiedColumns,
          tree: newTree,
          state,
          mapOfClonedToOldNodeName
        });
      }

    case actionTypes.LAYOUT_SECTION_CELL_CLONED_TO_RIGHT:
      {
        const {
          cellId,
          layoutSectionId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let cellToClone = cellId; // If cloning a single module in a column, clone the parent column instead

        const cell = tree.findCell(cellId);

        if (cell.isOnlyModuleInWrapperColumn()) {
          cellToClone = cell.getParent().getParentName();
        }

        const {
          tree: newTree,
          modifiedColumns,
          mapOfClonedToOldNodeName
        } = tree.cloneNewCellToRight(cellToClone); // Bail if this tree action was a no-op

        if (tree === newTree) {
          return state;
        } // Pass along the cloned and renamed modules for middlewares to update the DOM


        payload.mapOfClonedToOldNodeName = mapOfClonedToOldNodeName;
        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          modifiedColumns,
          tree: newTree,
          state,
          mapOfClonedToOldNodeName
        });
      }

    case actionTypes.LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN:
    case actionTypes.LAYOUT_SECTION_ROW_APPENDED:
      {
        const {
          columnId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          shouldWrapSingleModuleInSection = !doNotWrapSingleModuleInSection,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = appendRowToCell(tree, columnId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree,
          shouldWrapSingleModuleInSection
        });
        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.LAYOUT_SECTION_ROW_PREPENDED:
      {
        const {
          columnId,
          existingRowId,
          layoutSectionId,
          originLayoutSectionId,
          newModuleType,
          shouldWrapSingleModuleInSection = !doNotWrapSingleModuleInSection,
          existingColumnId
        } = payload;
        const newModuleSchemaJson = prepareNewModuleDataHelper(payload.newModuleSchemaJson, newModuleType);
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let originTree;

        if (originLayoutSectionId !== layoutSectionId) {
          originTree = getLayoutSectionTreeToModify(state, originLayoutSectionId);
        }

        const {
          tree: newTree,
          originTree: newOriginTree,
          modifiedColumns
        } = prependRowToCell(tree, columnId, {
          existingColumnId,
          existingRowId,
          newModuleSchemaJson,
          originTree,
          shouldWrapSingleModuleInSection
        }); // Bail if this tree action was a no-op

        if (tree === newTree && originTree === newOriginTree) {
          return state;
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          originLayoutSectionId,
          newModuleSchemaJson,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.MODULE_RESET_LAYOUT_STYLES:
    case actionTypes.LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          cellId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoCellValue(cellId, {
          styles: {}
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.MOBILE_LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          cellId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoCellValue(cellId, {
          mobileMargin: {},
          mobilePadding: {}
        }, {
          path: ['styles']
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.MODULE_SET_LAYOUT_STYLES:
    case actionTypes.LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          cellId,
          newLayoutStyles
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoCellStyles(cellId, newLayoutStyles).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          rowId,
          newLayoutStyles
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoRowStyles(rowId, newLayoutStyles).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.SET_BREAKPOINT_STYLES_FOR_TREE_NODE:
      {
        const {
          layoutSectionId,
          nodeId,
          newLayoutStyles,
          breakpoint
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let node;
        const isCell = tree.hasCell(nodeId);

        if (isCell) {
          node = tree.findCell(nodeId);
        } else {
          node = tree.findRow(nodeId);
        }

        const existingLayoutStyles = node.getLayoutStyleData() || {};
        const existingBreakpointStyles = existingLayoutStyles.breakpointStyles || {}; // Create new object reference, copying refrences to all the existing styles set in that breakpoint

        const newStylesForThisBreakpoint = Object.assign({}, // New object reference for this breakpoint
        existingBreakpointStyles[breakpoint], // Copy over references to all the existing styles objects
        newLayoutStyles // Override just the styles that changed
        ); // Create new object reference, copying references to all the existing breakpoint objects

        const newBreakpointStyles = Object.assign({}, // New object reference
        existingBreakpointStyles, // Copy over references to all the existing breakpoint objects
        {
          [breakpoint]: newStylesForThisBreakpoint // Override just the breakpoint we are modifying with the `newStylesForThisBreakpoint` object from above

        });
        let newTree;

        if (isCell) {
          newTree = tree.mergeIntoCellStyles(nodeId, {
            breakpointStyles: newBreakpointStyles
          }).tree;
        } else {
          newTree = tree.mergeIntoRowStyles(nodeId, {
            breakpointStyles: newBreakpointStyles
          }).tree;
        }

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          rowId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoRowValue(rowId, {
          styles: {}
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.MOBILE_LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES:
      {
        const {
          layoutSectionId,
          rowId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoRowValue(rowId, {
          mobileMargin: {},
          mobilePadding: {}
        }, {
          path: ['styles']
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT:
      {
        const {
          layoutSectionId,
          rowId,
          verticalAlignment
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = setAlignmentOnAllColumnsInRow(tree, rowId, verticalAlignment).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_SET_CELL_CSS_ID:
      {
        const {
          layoutSectionId,
          cellId,
          cssId
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoCellParams(cellId, {
          css_id: cssId
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_SET_CELL_CSS_STYLES:
      {
        const {
          layoutSectionId,
          cellId,
          cssStyles
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = tree.mergeIntoCellParams(cellId, {
          css_style: cssStyles
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_SET_CELL_CSS_CLASS:
      {
        const {
          layoutSectionId,
          cellId,
          cssClass
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newClassString = cssClass;
        const newTree = tree.mergeIntoCellParams(cellId, {
          css_class: newClassString
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_SET_ROW_CSS_CLASS:
      {
        const {
          layoutSectionId,
          rowId,
          cssClass
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newClassString = cssClass;
        const newTree = tree.mergeIntoRowValue(rowId, {
          cssClass: newClassString
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_ADD_CLASS_TO_CELL:
      {
        const {
          layoutSectionId,
          cellId,
          cssClass
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const currentParams = tree.findCell(cellId).getParams();
        const newClassString = `${currentParams.css_class} ${cssClass}`;
        const newTree = tree.mergeIntoCellParams(cellId, {
          css_class: newClassString
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_REMOVE_CLASS_FROM_CELL:
      {
        const {
          layoutSectionId,
          cellId,
          cssClass
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const currentParams = tree.findCell(cellId).getParams();
        const classRegex = new RegExp(`(?:(\\s|^))${cssClass}(?:(\\s|$))`, 'g');
        const newClassString = currentParams.css_class.replace(classRegex, '');
        const newTree = tree.mergeIntoCellParams(cellId, {
          css_class: newClassString
        }).tree;

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_COLUMNS_RESIZED:
      {
        const {
          layoutSectionId,
          leftColumnInfo,
          rightColumnInfo
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        const newTree = modifyColumnWidths(tree, leftColumnInfo, rightColumnInfo);

        if (state.getIn(['layoutSectionTrees', layoutSectionId]) !== newTree) {
          state = state.setIn(['layoutSectionTrees', layoutSectionId], newTree);
        }

        return state;
      }

    case actionTypes.LAYOUT_SECTION_SET_LABEL:
      {
        const {
          id,
          partialBody,
          isRow
        } = payload;
        return mergeLayoutFragmentDataHelper(state, id, partialBody, isRow);
      }

    case actionTypes.SET_MODULE_BODY:
      {
        const {
          id,
          body
        } = payload;
        const key = [...getKeyForNonLayoutSection(state, id), 'body'];
        const tree = findTreeWithCellName(state, id);
        state = incrementModuleEditVersion({
          state,
          id
        });

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(id); // CSS data from template is stored in the cell params, but with CMV1 templates
          // we compeltely replace the params of a cell with the new module body coming in.
          // This is special casing the css data to make sure it doesn't get blown away on the layout
          // tree cell when a module body is replaced.

          const existingCellParams = cell.getParams();
          const existingCellStyles = {
            css_id: existingCellParams.css_id,
            css_class: existingCellParams.css_class,
            css_style: existingCellParams.css_style
          };
          const newParamsToSet = Object.assign({}, existingCellStyles, {}, body);
          ({
            tree: newTree
          } = newTree.modifyCellParams(cell.getName(), newParamsToSet));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        return state.setIn(key, Immutable.fromJS(body));
      }

    case actionTypes.SET_DEPRECATED_STYLES_FLAGS:
    case actionTypes.MERGE_MODULE_BODY:
      {
        const {
          id,
          partialBody,
          moduleDefaultType,
          metaData
        } = payload;
        state = updateMetaDataForModule(state, id, metaData);
        state = incrementModuleEditVersion({
          state,
          id
        });
        return mergeModuleBodyHelper(state, id, partialBody, {
          moduleDefaultType
        });
      }

    case actionTypes.MERGE_MODULE_DATA:
      {
        const {
          id,
          partialBody,
          metaData,
          layoutSectionId
        } = payload;
        state = updateMetaDataForModule(state, id, metaData);
        state = incrementModuleEditVersion({
          state,
          id
        });
        return mergeModuleDataHelper(state, id, partialBody, layoutSectionId);
      }

    case actionTypes.MERGE_MODULE_META_DATA:
      {
        const {
          id,
          metaData,
          incrementEditVersion
        } = payload;
        const updatedState = updateMetaDataForModule(state, id, metaData);

        if (incrementEditVersion) {
          return incrementModuleEditVersion({
            state: updatedState,
            id
          });
        }

        return updatedState;
      }

    case actionTypes.SET_MODULE_SMART_OBJECT_BODY:
      {
        const {
          id,
          smartObjectIndex,
          body,
          updateEditVersion
        } = payload;
        const keyBase = getKeyForNonLayoutSection(state, id);
        const tree = findTreeWithCellName(state, id);

        if (updateEditVersion) {
          state = incrementModuleEditVersion({
            state,
            id
          });
        }

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(id);
          const smartObjectsFromTree = cell.getParams().smart_objects;
          const immutableSmartObjects = Immutable.Iterable.isIterable(smartObjectsFromTree) ? smartObjectsFromTree : Immutable.fromJS(smartObjectsFromTree);
          const newParamPartial = {
            smart_objects: immutableSmartObjects.mergeIn([smartObjectIndex, 'body'], body).toJS()
          };
          ({
            tree: newTree
          } = newTree.mergeIntoCellParams(cell.getName(), newParamPartial));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        const key = [...keyBase, 'smart_objects', smartObjectIndex, 'body'];
        return state.setIn(key, Immutable.fromJS(body));
      }

    case actionTypes.MERGE_MODULE_SMART_OBJECT_BODY:
      {
        const {
          id,
          metaData,
          partialBody,
          smartObjectIndex,
          updateEditVersion
        } = payload;
        state = updateMetaDataForModule(state, id, metaData);
        const keyBase = getKeyForNonLayoutSection(state, id);
        const tree = findTreeWithCellName(state, id);

        if (updateEditVersion) {
          state = incrementModuleEditVersion({
            state,
            id
          });
        }

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(id);
          const smartObjectsFromTree = cell.getParams().smart_objects;
          const immutableSmartObjects = Immutable.Iterable.isIterable(smartObjectsFromTree) ? smartObjectsFromTree : Immutable.fromJS(smartObjectsFromTree);
          const newParamPartial = {
            smart_objects: immutableSmartObjects.mergeIn([smartObjectIndex, 'body'], partialBody).toJS()
          };
          ({
            tree: newTree
          } = newTree.mergeIntoCellParams(cell.getName(), newParamPartial));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        const key = [...keyBase, 'smart_objects', smartObjectIndex, 'body'];
        return state.mergeIn(key, Immutable.fromJS(partialBody));
      }

    case actionTypes.SET_MODULE_STYLES:
      {
        const {
          id,
          styles
        } = payload;
        const pathToKey = id === BODY_ID ? ['contentCss', BODY_ID] : [...getKeyForNonLayoutSection(state, id), 'css'];
        const tree = findTreeWithCellName(state, id);

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(id);
          ({
            tree: newTree
          } = newTree.mergeIntoCellParams(cell.getName(), styles));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        if (styles.css) {
          state = state.setIn(pathToKey, Immutable.fromJS(styles.css));
        }

        if (styles.child_css) {
          state = state.setIn([...getKeyForNonLayoutSection(state, id), 'child_css'], Immutable.fromJS(styles.child_css));
        }

        return state;
      }

    case actionTypes.SET_MODULE_BREAKPOINT_STYLES:
      {
        const {
          id,
          styles,
          activeBreakpoint
        } = payload;
        const stylePath = [...getKeyForNonLayoutSection(state, id), 'styles'];
        const breakpointPath = [...stylePath, 'breakpointStyles', activeBreakpoint];
        const existingBreakpointStyles = state.getIn(breakpointPath, new ImmutableMap());
        const newBreakpointStyles = existingBreakpointStyles.merge(Immutable.fromJS(styles));
        state = state.setIn(breakpointPath, newBreakpointStyles);
        return state;
      }

    case actionTypes.REMOVE_FAKE_BODY_MODULE_STYLES:
      {
        return state.removeIn(['contentCss', BODY_ID]);
      }

    case actionTypes.UPDATED_CONTAINERS_FROM_PREVIEW:
      {
        const {
          containers
        } = payload;
        return containers.reduce((currentState, container) => {
          const {
            id,
            widgets
          } = container;
          const modules = new Immutable.List(widgets.map((moduleId, order) => state.getIn(getKeyForNonLayoutSection(state, moduleId)).set('order', order)));
          return currentState.setIn(['widgetContainers', id, 'widgets'], modules);
        }, state);
      }

    case actionTypes.UPDATED_MODULE_SMART_RULES:
      {
        const {
          id,
          smartObject: {
            body,
            definition_id,
            smart_type,
            smart_objects
          }
        } = payload;
        const key = getKeyForNonLayoutSection(state, id);
        const tree = findTreeWithCellName(state, id);
        const contentsToMerge = {
          smart_objects
        };

        if (smart_objects.length && (!definition_id || !smart_type)) {
          captureMessage('Trying to update smart objects with null definition_id or null smart_type in non-deletion action', {
            level: 'warning',
            extra: payload
          });
        } else {
          contentsToMerge.definition_id = definition_id;
          contentsToMerge.smart_type = smart_type;
        }

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(id);
          const paramsToMerge = body ? Object.assign({}, body, {}, contentsToMerge) : contentsToMerge;
          ({
            tree: newTree
          } = newTree.mergeIntoCellParams(cell.getName(), paramsToMerge));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        if (body && body.size > 0) {
          state = state.setIn([...key, 'body'], Immutable.fromJS(body));
        }

        return state.mergeIn(key, contentsToMerge);
      }

    case actionTypes.REMOVED_MODULE_SMART_RULES:
      {
        const key = getKeyForNonLayoutSection(state, payload.id);
        const tree = findTreeWithCellName(state, payload.id);

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(payload.id);
          const modifiedParams = cell.getParams();
          modifiedParams.definition_id = null;
          modifiedParams.smart_type = null;
          modifiedParams.smart_objects = new Immutable.List();
          ({
            tree: newTree
          } = newTree.modifyCellParams(cell.getName(), modifiedParams));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        return state.setIn([...key, 'definition_id'], null).setIn([...key, 'smart_type'], null).setIn([...key, 'smart_objects'], new Immutable.List());
      }

    case actionTypes.RESYNCHRONIZE_MODULE_SMART_OBJECTS:
      {
        const {
          moduleId,
          smartObjects
        } = payload;
        const key = [...getKeyForNonLayoutSection(state, moduleId), 'smart_objects'];
        const tree = findTreeWithCellName(state, moduleId);

        if (tree) {
          state = copyLayoutSectionFromSchemaToContentIfNeeded(state, tree.getRootName()); // Make sure to get cell reference in newly modified tree

          let newTree = state.getIn(['layoutSectionTrees', tree.getRootName()]);
          const cell = tree.findCell(moduleId);
          ({
            tree: newTree
          } = newTree.mergeIntoCellParams(cell.getName(), {
            smart_objects: smartObjects
          }));
          return state.setIn(['layoutSectionTrees', tree.getRootName()], newTree);
        }

        return state.setIn(key, smartObjects);
      }

    case actionTypes.CLONED_MODULES_QUEUE_CLEARED:
      {
        // Clear all nodes from pending width queue
        state = state.set('clonedModulesPendingDomOperations', new Immutable.Set());
        return state;
      }

    case actionTypes.ADDED_MODULES_TO_FAKE_MODULES:
      {
        const {
          modules
        } = payload;
        return state.set('fakeModules', state.get('fakeModules').merge(Immutable.fromJS(modules)));
      }

    case actionTypes.ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES:
      {
        const {
          customUpdates
        } = payload;
        return state.mergeIn(['fakeModuleCustomUpdates'], Immutable.fromJS(customUpdates));
      }

    case actionTypes.CUSTOM_LAYOUT_SECTION_ADDED:
      {
        const {
          layoutSectionId,
          rowIdToInsertAround,
          targetBeforeOrAfter,
          isEmptyLayoutSection,
          rowIdToMove,
          tempCustomSectionTree,
          customSectionModuleSchemas
        } = payload;
        const tree = getLayoutSectionTreeToModify(state, layoutSectionId);
        let newTree;
        let newOriginTree;
        let modifiedColumns;

        if (isEmptyLayoutSection) {
          ({
            tree: newTree,
            originTree: newOriginTree,
            modifiedColumns
          } = appendRowToCell(tree, layoutSectionId, {
            existingRowId: rowIdToMove,
            customSectionModuleSchemas,
            originTree: tempCustomSectionTree
          }));
        } else {
          const insertMethod = targetBeforeOrAfter === BEFORE ? insertRowBefore : insertRowAfter;
          ({
            tree: newTree,
            originTree: newOriginTree,
            modifiedColumns
          } = insertMethod(tree, rowIdToInsertAround, {
            existingRowId: rowIdToMove,
            customSectionModuleSchemas,
            originTree: tempCustomSectionTree
          }));
        }

        return updateLayoutSectionTreeAndWidgets({
          layoutSectionId,
          customSectionModuleSchemas,
          modifiedColumns,
          tree: newTree,
          originTree: newOriginTree,
          state
        });
      }

    case actionTypes.UPDATE_STATIC_MODULE_WITH_NEW_ID:
      {
        const {
          id,
          newModule
        } = payload;
        state = state.setIn(['widgets', newModule.get('name')], newModule);
        state = state.setIn(['schemaWidgets', newModule.get('name')], newModule);
        state = state.deleteIn(['schemaWidgets', id]);
        state = state.deleteIn(['widgets', id]);
        return state;
      }

    case actionTypes.RESET_LAYOUT_SECTION_TO_TEMPLATE_DEFAULT:
      {
        const {
          id
        } = payload;
        state = copyLayoutSectionFromSchemaToContent(state, id, state.get('layoutSectionWidgets'), {
          isUngatedForMergeInDefaultValues: state.get('isUngatedForMergeInDefaultValues')
        });
        return state;
      }

    default:
      return state;
  }
};

export default moduleReducer;