'use es6';

import { createSelector } from 'reselect';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { getHasContainers, getHasAnyLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getIsReadOnlyMode, getIframeReady, getAddModuleSidebarLoaded } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { isApplyChangesModalOpen } from 'ContentEditorUI/redux/selectors/modalSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getSidebarState = basicSelector(state => state.sidebarState);
export const getActiveSidebarPanel = createSelector([getSidebarState, getIsReadOnlyMode], (sidebarState, isReadOnlyMode) => {
  const currentActivePanel = sidebarState.get('activePanel');
  let activePanel = sidebarState ? currentActivePanel : '';

  if (isReadOnlyMode && activePanel === SIDEBAR_PANELS.addWidget) {
    activePanel = SIDEBAR_PANELS.widgetList;
  }

  return activePanel;
});
export const getSidebarPanels = createSelector([getHasContainers, getHasAnyLayoutSections, getIsReadOnlyMode], (hasContainers, hasAnyLayoutSections, isReadOnlyMode) => {
  const panels = [SIDEBAR_PANELS.preview, SIDEBAR_PANELS.optimizer, SIDEBAR_PANELS.widgetList, SIDEBAR_PANELS.edit];

  if (!isReadOnlyMode && (hasContainers || hasAnyLayoutSections)) {
    panels.push(SIDEBAR_PANELS.addWidget);
  }

  return panels;
}); // NOTE These are editSidebar selectors. It's a different piece of redux state,
// but we're keping them in the same selector file because we should eventually
// consolidate them.

const getEditSidebar = basicSelector(state => state.editSidebar);
export const getEditSidebarActiveTab = createSelector([getEditSidebar], editSidebar => editSidebar.get('activeTab'));
export const getIsBaymaxSidebarCollapsed = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarCollapsed'));
export const getIsPatagoniaSidebarCollapsed = createSelector([getSidebarState], sidebarState => sidebarState.get('patagoniaSidebarCollapsed'));
export const getIsSidebarExpanded = createSelector([getActiveSidebarPanel, getIsBaymaxSidebarCollapsed], (activeSidebarPanel, baymaxSidebarCollapsed) => activeSidebarPanel !== '' && !baymaxSidebarCollapsed);
export const getIsBaymaxSidebarHidden = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarHidden'));
export const getIsSidebarCollapseAnimationFinished = createSelector([getSidebarState], sidebarState => sidebarState.get('sidebarCollapseAnimationFinished'));
export const getIsPatagoniaSidebarCollapseAnimationFinished = createSelector([getSidebarState], sidebarState => sidebarState.get('patagoniaSidebarCollapseAnimationFinished'));
export const getSidebarIframeIsVisible = createSelector([getIsBaymaxSidebarHidden, getIsBaymaxSidebarCollapsed, getIsSidebarCollapseAnimationFinished, getActiveSidebarPanel], (sidebarHidden, sidebarCollapsed, collapseAnimationFinished, activePanel) => !sidebarHidden && (!sidebarCollapsed || sidebarCollapsed && !collapseAnimationFinished) && activePanel === SIDEBAR_PANELS.addWidget);
export const getPatagoniaSidebarIframeVisible = createSelector([getIsBaymaxSidebarHidden, getIsPatagoniaSidebarCollapsed, getIsPatagoniaSidebarCollapseAnimationFinished, getActiveSidebarPanel], (sidebarHidden, sidebarCollapsed, collapseAnimationFinished, activePanel) => !sidebarHidden && (!sidebarCollapsed || sidebarCollapsed && !collapseAnimationFinished) && activePanel === SIDEBAR_PANELS.addWidget);
export const getSidebarIframeNeedsLoadingIndicator = createSelector([getIframeReady, getAddModuleSidebarLoaded, getActiveSidebarPanel], (iframeReady, addModuleSidebarLoaded, activePanel) => {
  return (!iframeReady || !addModuleSidebarLoaded) && activePanel === SIDEBAR_PANELS.addWidget;
});
export const getModuleEditSidebarNeedsLoadingIndicator = createSelector([getIframeReady, getActiveSidebarPanel], (iframeReady, activePanel) => {
  return !iframeReady && activePanel === SIDEBAR_PANELS.edit;
});
export const getScrollToModule = createSelector([getSidebarState], sidebarState => sidebarState.get('scrollToModule'));
export const getApplyChangesButtonOpen = createSelector([getEditSidebar, isApplyChangesModalOpen], (editSidebar, applyChangesModalOpen) => editSidebar.get('applyChangesButtonOpen') && !applyChangesModalOpen);
export const getPreviousSidebarPanel = createSelector([getSidebarState], sidebarState => {
  return sidebarState.get('previousPanel');
});
export const getSelectedField = createSelector([getEditSidebar], sidebarState => sidebarState.get('selectedField'));
export const getSidebarCategory = createSelector([getSidebarState], sidebarState => sidebarState.get('category'));
export const getSidebarSubCategory = createSelector([getSidebarState], sidebarState => sidebarState.get('subCategory')); // generally will be be the first item, but can be overridden
// E.g. Don't show an empty section when a useful section exists

export const getDefaultSubCategoryMap = createSelector([getSidebarState], sidebarState => sidebarState.get('defaultSelectedSubCategoryMap'));
export const getXraySidebarSection = createSelector([getSidebarState], sidebarState => sidebarState.get('xraySection'));