import { CompletionStatuses } from '../../../constants';
export const removeDomainFromTitleAudit = (artifacts, editorApi) => {
  const pageTitle = artifacts.pageTitle;
  const domain = editorApi.activeDomainForXray;
  let status;

  if (pageTitle === '' || pageTitle === undefined) {
    status = CompletionStatuses.BLOCKED;
  } else if (pageTitle.includes(domain)) {
    status = CompletionStatuses.INCOMPLETE;
  } else {
    status = CompletionStatuses.COMPLETE;
  }

  return {
    status
  };
};