'use es6';

import { List, Map as ImmutableMap, fromJS } from 'immutable';
import { exportCellAsModule } from 'layout-data-lib/LayoutDataTree/serialize';
import { createSelector } from 'reselect';
import { createUndoableSelector } from 'ContentEditorUI/redux/selectors/undoRedoSelectors';
import { getAllFieldNamesOnModuleSpec } from 'ContentEditorUI/data/moduleUtils';
import { getModuleSchemas, getAllModuleSchemasByModuleId } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { normalizedId, normalizedKey, getWidgetBodyFromSchema, defaultContentWidgetAttributes, attributesPickedFromContentWidget, getContentWidgetForWidgetOutsideContainer, ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET } from 'ContentEditorUI/data/moduleTransformHelpers';
import { getAuth, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody } from './authSelectors';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getUndoableModules = basicSelector(state => state.modules);
export const getModuleLists = createUndoableSelector(getUndoableModules, 'present');
export const buildFakeModuleSlice = (moduleLists, auth) => ({
  modules: new ImmutableMap({
    present: moduleLists
  }),
  auth
});

const createModuleListsSelector = key => createSelector(getModuleLists, moduleLists => moduleLists ? moduleLists.get(key) : ImmutableMap());

const getSchemaWidgets = createModuleListsSelector('schemaWidgets');
export const getContentWidgets = createModuleListsSelector('widgets');
const getSchemaWidgetContainers = createModuleListsSelector('schemaWidgetContainers');
const getContentWidgetContainers = createSelector(getModuleLists, moduleLists => {
  let widgetContainers = moduleLists.get('widgetContainers'); // Inspect the widgetContainers to make sure each has atleast an empty
  // widgets list. Some legacy pages don't have this

  widgetContainers.forEach((container, key) => {
    if (!container.has('widgets')) {
      const emptyContainer = container.set('widgets', new List());
      widgetContainers = widgetContainers.set(key, emptyContainer);
    }
  });
  return widgetContainers;
});
export const getSchemaWidgetsInFlexColumnsByKey = createSelector(getSchemaWidgetContainers, schemaWidgetContainers => schemaWidgetContainers.map(schemaContainer => new ImmutableMap(schemaContainer.get('widgets').map(module => [module.get('key'), module]))));
export const getContentWidgetsInFlexColumnsById = createSelector(getContentWidgetContainers, contentWidgetContainers => contentWidgetContainers.map(contentContainer => new ImmutableMap(contentContainer.get('widgets').map(module => [module.get('id'), module]))));
export const getSchemaLayoutSectionTrees = createModuleListsSelector('schemaLayoutSectionTrees');
export const getContentLayoutSectionTrees = createModuleListsSelector('layoutSectionTrees');
export const getContentFlexAreaSections = createModuleListsSelector('flexAreaSections');
export const getContentFlexAreaStyles = createModuleListsSelector('flexAreaStyles');
export const getUserModuleDefaults = createSelector([getModuleLists], moduleLists => moduleLists.get('userModuleDefaults'));
const getAllTreeNames = createSelector(getSchemaLayoutSectionTrees, schemaLayoutSectionTrees => schemaLayoutSectionTrees.keySeq().toArray());
const getLayoutSectionWidgets = createSelector(getModuleLists, moduleLists => moduleLists.get('layoutSectionWidgets')); // Fancy multi-level memoization selectors for modules outside a D&D area
// A little weird, but a function to _fake_ the global state with only the parts needed
// for the fancy custom internal selectors used by `makeCachedStaticModulesSelector`
// (so other standard selectors like `getSchemaWidgets` can be re-used across both)

const buildFakeOutsideContainerState = (schemaWidgets, contentWidgets, auth) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaWidgets,
      widgets: contentWidgets
    })
  }),
  auth
});

const makeGetSpecificSchemaWidgetOutsideContainer = moduleName => createSelector(getSchemaWidgets, schemaWidgets => schemaWidgets.get(moduleName));

const makeGetSpecificContentWidgetOutsideContainer = (moduleName, getSpecificSchemaWidgetOutsideContainer) => createSelector(getSpecificSchemaWidgetOutsideContainer, getContentWidgets, (schemaWidget, contentWidgets) => getContentWidgetForWidgetOutsideContainer(schemaWidget, contentWidgets));

export const hydrateWidgetFromSchemaAndContent = (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody) => {
  const id = normalizedId(schemaWidget, contentWidget);
  const key = normalizedKey(schemaWidget, contentWidget);
  return defaultContentWidgetAttributes(schemaWidget, contentWidget).merge(schemaWidget).set('body', schemaWidget.isEmpty() ? contentWidget.get('body') : getWidgetBodyFromSchema(schemaWidget)).merge(attributesPickedFromContentWidget(contentWidget, schemaWidget, {
    isUngatedToMergeTemplateSchemaBodyIntoContentBody
  })).set('id', id).set('key', key);
};

const makeGetSpecificModuleOutsideContainer = moduleName => {
  const getSpecificSchemaWidgetOutsideContainer = makeGetSpecificSchemaWidgetOutsideContainer(moduleName);
  return createSelector(getSpecificSchemaWidgetOutsideContainer, makeGetSpecificContentWidgetOutsideContainer(moduleName, getSpecificSchemaWidgetOutsideContainer), getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, hydrateWidgetFromSchemaAndContent);
};

export const makeCachedStaticModulesSelector = () => {
  const cachedModuleSelectors = {};
  return createSelector(getSchemaWidgets, getContentWidgets, getAuth, (schemaWidgets, contentWidgets, auth) => {
    return schemaWidgets.filter(schemaWidget => {
      // Exclude any "modules" that are inside a global group or partial and not directly uneditable
      const isInsideGlobalGroupOrPartial = schemaWidget.has('global_group_path') || schemaWidget.has('global_partial_path');
      return !isInsideGlobalGroupOrPartial;
    }).map(schemaWidget => {
      const moduleName = schemaWidget.get('name') || schemaWidget.get('id');

      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleOutsideContainer(moduleName);
      }

      const fakedState = buildFakeOutsideContainerState(schemaWidgets, contentWidgets, auth);
      return cachedModuleSelectors[moduleName](fakedState);
    });
  });
}; // Fancy multi-level memoization selectors for modules inside flex columns

const buildFakeModuleSliceStateForFlexColumns = (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaWidgetContainers,
      widgetContainers: contentWidgetContainers
    })
  }),
  auth,
  moduleSchemas
});

const makeGetSpecificSchemaWidgetInsideFlexColumn = (moduleId, containerId) => createSelector(getSchemaWidgetsInFlexColumnsByKey, schemaWidgetsById => schemaWidgetsById.get(containerId).get(moduleId));

const makeGetSpecificContentWidgetInsideFlexColumn = (moduleId, containerId) => createSelector(getContentWidgetsInFlexColumnsById, contentWidgetsById => {
  return contentWidgetsById.get(containerId, ImmutableMap()).get(moduleId);
});

const makeCachedSpecificModuleInsideFlexColumnSelector = (moduleId, containerId) => {
  const getSpecificSchemaWidgetInsideFlexColumn = makeGetSpecificSchemaWidgetInsideFlexColumn(moduleId, containerId);
  const getSpecificContentWidgetInsideFlexColumn = makeGetSpecificContentWidgetInsideFlexColumn(moduleId, containerId);
  return createSelector(getSpecificSchemaWidgetInsideFlexColumn, getSpecificContentWidgetInsideFlexColumn, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, getAllModuleSchemasByModuleId, (schemaWidget, contentWidget, isUngatedToMergeTemplateSchemaBodyIntoContentBody, allModuleSchemasByModuleId) => {
    const id = normalizedId(schemaWidget, contentWidget);
    const key = normalizedKey(schemaWidget, contentWidget);
    const moduleSpecId = schemaWidget && schemaWidget.get('module_id') || contentWidget && contentWidget.get('module_id');
    let moduleSpec;

    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];
    } // Hydrate widget from schema and contentWidget


    return defaultContentWidgetAttributes(schemaWidget, contentWidget).merge(schemaWidget).set('body', getWidgetBodyFromSchema(schemaWidget)).merge(attributesPickedFromContentWidget(contentWidget, schemaWidget, {
      isUngatedToMergeTemplateSchemaBodyIntoContentBody,
      moduleSpec
    })).merge({
      name: id,
      id,
      key,
      containerId,
      isInContainer: true,
      // Force any modules in a flex column (even ones set in the default layout of a template)
      // to be editable
      overrideable: true
    });
  });
};

const makeGetFlexColumn = containerId => createSelector(getSchemaWidgetContainers, getContentWidgetContainers, (schemaWidgetContainers, contentWidgetContainers) => contentWidgetContainers.get(containerId) || schemaWidgetContainers.get(containerId));

export const makeGetCachedModulesInsideSpecificFlexColumn = containerId => {
  const cachedModuleSelectors = {};
  const getFlexColumn = makeGetFlexColumn(containerId);
  return createSelector(getFlexColumn, getSchemaWidgetContainers, getContentWidgetContainers, getAuth, getModuleSchemas, (flexColumn, schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumns(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    return flexColumn.get('widgets').map(module => // Fall back to using "key" when id does not exist (in widgets from schema containers)
    module.has('id') ? module.get('id') : module.get('key')).map((moduleId, order) => {
      if (!cachedModuleSelectors[moduleId]) {
        cachedModuleSelectors[moduleId] = makeCachedSpecificModuleInsideFlexColumnSelector(moduleId, containerId);
      }

      return cachedModuleSelectors[moduleId](fakedState, {
        from: 'makeGetCachedModulesInsideSpecificFlexColumn'
      }).set('order', order); // Note this order set will force many more module reference updates that really needed.
      // Probably should move the order state _out_ of the module sooner or later to prevent that.
      // However, I (TF) am not super worried about perfecting the performance of flex columns,
      // and think we can live with this until flex columns slowly die off.
    });
  });
};
export const makeGetOrderedModuleIdsInsideSpecificFlexColumn = containerId => {
  const getFlexColumn = makeGetFlexColumn(containerId);
  return createSelector(getFlexColumn, flexColumn => flexColumn.get('widgets').map(module => module.get('id')));
};
export const makeCachedModulesInsideAllFlexColumnsSelector = () => {
  const cachedModulesInContainerSelector = {};
  return createSelector(getSchemaWidgetContainers, getContentWidgetContainers, getAuth, getModuleSchemas, (schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas) => {
    const fakedState = buildFakeModuleSliceStateForFlexColumns(schemaWidgetContainers, contentWidgetContainers, auth, moduleSchemas);
    const modulesByContainer = schemaWidgetContainers.map((container, containerId) => {
      if (!cachedModulesInContainerSelector[containerId]) {
        cachedModulesInContainerSelector[containerId] = makeGetCachedModulesInsideSpecificFlexColumn(containerId);
      }

      return cachedModulesInContainerSelector[containerId](fakedState);
    });
    const containerModules = modulesByContainer.map((modulesInContainer, containerId) => schemaWidgetContainers.get(containerId).merge({
      type: 'container',
      id: containerId,
      widgets: modulesInContainer
    })); // Convert to Map of modules by name

    return new ImmutableMap(modulesByContainer.valueSeq().flatMap(modules => modules.map(m => [m.get('name'), m]))).merge(containerModules);
  });
}; // Fancy multi-level memoization selectors for modules in a D&D area

export const getLayoutSectionWidgetsModuleMap = createSelector(getLayoutSectionWidgets, layoutSectionWidgets => {
  return ImmutableMap(layoutSectionWidgets.valueSeq().flatMap(modulesForTree => {
    return modulesForTree.map(m => [m.get('name'), m]);
  }));
});

const buildFakeModuleSliceStateForTrees = (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaLayoutSectionTrees,
      layoutSectionTrees: contentLayoutSectionTrees,
      layoutSectionWidgets
    })
  }),
  auth,
  moduleSchemas
});

const buildFakeModuleSliceStateForSpecificTree = (treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => ({
  modules: ImmutableMap({
    present: ImmutableMap({
      schemaLayoutSectionTrees: ImmutableMap({
        [treeName]: schemaTree
      }),
      layoutSectionTrees: ImmutableMap(contentTree ? {
        [treeName]: contentTree
      } : {}),
      layoutSectionWidgets
    })
  }),
  auth,
  moduleSchemas
});

const makeGetSpecificSchemaTree = treeName => createSelector(getSchemaLayoutSectionTrees, schemaTrees => schemaTrees.get(treeName));

const makeGetSpecificContentTree = treeName => createSelector(getContentLayoutSectionTrees, contentTrees => contentTrees.get(treeName));

const makeGetTreeCellValueFor = (nodeName, treeName) => createSelector(makeGetSpecificSchemaTree(treeName), makeGetSpecificContentTree(treeName), (schemaTree, contentTree) => contentTree ? contentTree.findCell(nodeName).getValue() : schemaTree.findCell(nodeName).getValue());

const makeGetContentTreeExistsFor = treeName => createSelector(getContentLayoutSectionTrees, contentTrees => contentTrees.has(treeName));

const makeGetLayoutSectionWidgetsModuleFor = moduleName => createSelector(getLayoutSectionWidgetsModuleMap, layoutSectionWidgetsModuleMap => layoutSectionWidgetsModuleMap.get(moduleName));

export const makeGetSpecificModuleInsideTree = (moduleName, treeName) => {
  return createSelector(makeGetTreeCellValueFor(moduleName, treeName), makeGetContentTreeExistsFor(treeName), makeGetLayoutSectionWidgetsModuleFor(moduleName), getAllModuleSchemasByModuleId, getIsUngatedToMergeTemplateSchemaBodyIntoContentBody, (treeNodeValue, isFromContentTree, widgetFromLSW, allModuleSchemasByModuleId, isUngatedToMergeTemplateSchemaBodyIntoContentBody) => {
    const treeModule = fromJS(exportCellAsModule(moduleName, treeNodeValue)); // Normally, the "base module" should come from the `layout_section_widgets` array from the
    // combined edit API response. However, in cases where a module in a tree is missing from that
    // `layout_section_widgets` array, fall back to the tree module to prevent all kinds of things
    // from breaking.
    //
    // Note, ideally ^ should never happen, but this bug has happened time and time again and I'm
    // concerned future edge cases will continue to bring it back (hence the defensiveness)

    const baseModule = widgetFromLSW || treeModule;
    const moduleSpecId = baseModule.get('module_id');
    let moduleSpec;
    let fieldTypeNames = [];

    if (moduleSpecId) {
      moduleSpec = allModuleSchemasByModuleId[moduleSpecId];

      if (moduleSpec) {
        fieldTypeNames = getAllFieldNamesOnModuleSpec(moduleSpec);
      }
    } // Only pull in "content widget" overriding values if we're iterating over a tree on
    // the content model (so not when these are modules from schemaLayoutSectionTrees)


    const contentWidget = isFromContentTree ? treeModule : null;
    let semiSerializedWidgetThing = baseModule.merge(Object.assign({
      id: baseModule.get('name'),
      layout_section_id: treeName
    }, attributesPickedFromContentWidget(contentWidget, widgetFromLSW, {
      sourcePrefix: 'params',
      moduleSpec,
      isUngatedToMergeTemplateSchemaBodyIntoContentBody
    })));

    if (contentWidget && contentWidget.get('params')) {
      // Filter out all schema attributes, except if a custom module field name matches one of those
      // schema attributes (like if a custom module has a `type` field)
      const attributesToFilter = ATTRIBUTES_PRIMARILY_FROM_CONTENT_WIDGET_WITH_EMPTY_SCHEMA_WIDGET.filter(attribute => !fieldTypeNames.includes(attribute));
      const filteredParams = contentWidget.get('params').filter((value, key) => !attributesToFilter.includes(key));
      semiSerializedWidgetThing = semiSerializedWidgetThing.mergeIn(['body'], filteredParams);
    }

    return semiSerializedWidgetThing;
  });
};
export const makeGetCachedModulesInsideTree = treeName => {
  const cachedModuleSelectors = {};
  return createSelector(makeGetSpecificSchemaTree(treeName), makeGetSpecificContentTree(treeName), getLayoutSectionWidgets, getAuth, getModuleSchemas, (schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas) => {
    const tree = contentTree || schemaTree;
    const fakedState = buildFakeModuleSliceStateForSpecificTree(treeName, schemaTree, contentTree, layoutSectionWidgets, auth, moduleSchemas);
    return tree.allModules().map(node => node.getName()).map(moduleName => {
      if (!cachedModuleSelectors[moduleName]) {
        cachedModuleSelectors[moduleName] = makeGetSpecificModuleInsideTree(moduleName, treeName);
      } // Note this code previously would set the order on each module here, but I got rid of it
      // because I believe it was unnecessary _and_ it always forced every module in the tree
      // to get a new reference (every time this selector was executed)


      return cachedModuleSelectors[moduleName](fakedState);
    });
  });
};
export const makeCachedModulesInsideLayoutSectionSelector = () => {
  const cachedModulesInTreeSelectors = {};
  return createSelector(getSchemaLayoutSectionTrees, getContentLayoutSectionTrees, getLayoutSectionWidgets, getAuth, getModuleSchemas, (schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const modules = schemaLayoutSectionTrees.keySeq().flatMap(treeName => {
      if (!cachedModulesInTreeSelectors[treeName]) {
        cachedModulesInTreeSelectors[treeName] = makeGetCachedModulesInsideTree(treeName);
      }

      const fakedState = buildFakeModuleSliceStateForTrees(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
      return cachedModulesInTreeSelectors[treeName](fakedState);
    }); // Convert List to Map

    return ImmutableMap(modules.map(m => [m.get('name'), m]));
  });
};

const makeGetRootModuleForLayoutSection = treeName => createSelector(makeGetSpecificSchemaTree(treeName), makeGetTreeCellValueFor(treeName, treeName), (schemaTree, treeRootValue) => {
  const schemaTreeValue = schemaTree.getRootCell().getValue();
  return new ImmutableMap({
    type: 'layout_section',
    name: treeName,
    label: schemaTreeValue.label || treeRootValue.label,
    order: schemaTreeValue.order,
    line_number: treeRootValue.line_number
  });
});

export const makeCachedRootModulesForEachLayoutSectionSelector = () => {
  const rootModuleSelectorCache = {};
  return createSelector(getAllTreeNames, getSchemaLayoutSectionTrees, getContentLayoutSectionTrees, getLayoutSectionWidgets, getAuth, getModuleSchemas, (allTreeNames, schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas) => {
    const fakeState = buildFakeModuleSliceStateForTrees(schemaLayoutSectionTrees, contentLayoutSectionTrees, layoutSectionWidgets, auth, moduleSchemas);
    return new List(allTreeNames).map(treeName => {
      if (!rootModuleSelectorCache[treeName]) {
        rootModuleSelectorCache[treeName] = makeGetRootModuleForLayoutSection(treeName);
      }

      return rootModuleSelectorCache[treeName](fakeState);
    });
  });
};
const ctaRegex = /\{\{\s*cta\(([^)]+)\)\s*\}\}/gi;
const videoRegex = /{%\s+video_player\s+("[a-zA-Z0-9,_]+"|'[a-zA-Z0-9,_]+').*?\s+player_id=("(?:[a-zA-Z0-9]+)".*?|'(?:[a-zA-Z0-9]+)'.*?)\s+%}/;
export const isRichTextModule = (module, builtInMapping) => {
  return builtInMapping['rich_text'] === module.get('module_id') || module.get('id') === 'post_body';
};
export const isRichTextModuleWithCta = (module, builtInMapping) => {
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }

  const rteHtml = module.getIn(['body', 'html']);
  return rteHtml ? !!rteHtml.match(ctaRegex) : false;
};
export const isRichTextModuleWithHsVideo = (module, builtInMapping) => {
  if (!isRichTextModule(module, builtInMapping)) {
    return false;
  }

  const rteHtml = module.getIn(['body', 'html']);
  return rteHtml ? !!rteHtml.match(videoRegex) : false;
};
export const isCtaModule = (module, mapping) => {
  return mapping['cta'] === module.get('module_id');
};
export const isVideoModuleWithHsVideo = module => {
  const body = module.get('body');
  return body && body.hasIn(['hubspot_video', 'player_id']) && body.get('video_type') === 'hubspot_video';
};
export const isOrHasCtaModule = (module, mapping) => isCtaModule(module, mapping) || isRichTextModuleWithCta(module, mapping);
export const isOrHasVideoModuleWithHsVideo = (module, builtInMapping) => isVideoModuleWithHsVideo(module) || isRichTextModuleWithHsVideo(module, builtInMapping);

const searchFieldTreeForRegexMatch = ({
  moduleFieldObject,
  schemaFieldsArray,
  regexArray,
  currentFieldKeyPath,
  fieldKeyPathsArray
}) => {
  if (!moduleFieldObject) {
    return fieldKeyPathsArray;
  }

  if (typeof moduleFieldObject !== 'object') {
    console.error(`currentFieldKeyPath "${currentFieldKeyPath}"'s moduleFieldObject is not an object \n moduleFieldObject value: ${moduleFieldObject}`);
    return fieldKeyPathsArray;
  }

  schemaFieldsArray.forEach(field => {
    if (field.children) {
      searchFieldTreeForRegexMatch({
        moduleFieldObject: moduleFieldObject.get(field.name),
        schemaFieldsArray: field.children,
        regexArray,
        currentFieldKeyPath: currentFieldKeyPath.concat([field.name]),
        fieldKeyPathsArray
      });
    } else if (field.type === 'richtext') {
      const value = moduleFieldObject.get(field.name);

      if (value && typeof value === 'string' && regexArray.some(regex => !!value.match(regex))) {
        fieldKeyPathsArray.push(currentFieldKeyPath.concat([field.name]));
      }
    }
  });
  return fieldKeyPathsArray;
};

export const searchModuleRichTextFieldsForRegexValidation = (moduleBody, moduleSchema, regexArray) => {
  if (!moduleBody || !moduleSchema) {
    return [];
  }

  return searchFieldTreeForRegexMatch({
    moduleFieldObject: moduleBody,
    schemaFieldsArray: moduleSchema.fields,
    regexArray,
    currentFieldKeyPath: [],
    fieldKeyPathsArray: []
  });
};
export const checkIfValueAtKeyMatchesRegex = (moduleBody, keyList, regexArray) => {
  if (!moduleBody) {
    return false;
  }

  const value = moduleBody.getIn(keyList);
  return value && regexArray.some(r => !!value.match(r));
};
export const getKeysThatMatchRegex = (moduleBody, keyListArray, regexArray) => {
  if (!moduleBody || !keyListArray || !regexArray) {
    return [];
  }

  return keyListArray.filter(keyList => checkIfValueAtKeyMatchesRegex(moduleBody, keyList, regexArray));
};