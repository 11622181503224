import { ApprovalStatus } from '../types/ApprovalStatus';
import { ApprovalUserType } from '../types/ApprovalUserType';
import { ApprovalOperation } from '../types/ApprovalOperation';
import { ApprovalStepStatus } from '../types';
export const getApprovalOperation = approval => {
  const {
    isLoading,
    approvalStatus,
    currentUserType,
    stepStatusForCurrentUser
  } = approval.meta;

  if (isLoading) {
    return null;
  }

  if (!approval.config.enabled) {
    return null;
  }

  switch (approvalStatus) {
    case ApprovalStatus.APPROVAL_PENDING:
      if (currentUserType === ApprovalUserType.APPROVER && stepStatusForCurrentUser === ApprovalStepStatus.APPROVAL_PENDING) {
        return ApprovalOperation.APPROVE;
      } else if (currentUserType === ApprovalUserType.REQUESTER) {
        return ApprovalOperation.RECALL;
      } else {
        return null;
      }

    case undefined:
    case ApprovalStatus.CANCELLED:
      return ApprovalOperation.REQUEST;

    case ApprovalStatus.APPROVED:
      if (approval.meta.restartOnConclude && approval.data && approval.data.approvalConcluded) {
        return ApprovalOperation.REQUEST;
      }

      return null;

    default:
      return null;
  }
};